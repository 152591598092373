import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Communication, MessageMedium, SendCommunicationRequest, State } from './communication.model';

import * as communicationActions from './communication.actions';
import { CommunicationService } from './communication.service';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as userActions from '../../state/user/user.actions';

import { AppState } from '../state';

type Action = communicationActions.All;

export const CommunicationsQuery = {
  isLoading: (state: AppState) => state.communication.loading,
  error: (state: AppState) => state.communication.error,
};

@Injectable()
export class CommunicationEffect {
  // error$ = this.store.select(CommunicationsQuery.error);
  // isLoading$ = this.store.select(CommunicationsQuery.isLoading);

  private cancelQueryState$ = new Subject<void>();
  private cancelQueryObservable$ = this.cancelQueryState$.asObservable();
  private queryState$ = new Subject<boolean>();
  private queryObservable$ = this.cancelQueryState$.asObservable();
  query$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<communicationActions.Query>(communicationActions.QUERY),
      withLatestFrom(this.auth.user$),
      switchMap(([query, user]) => {
        const pid = user?.uid.substring('Patient-'.length);
        return this.db
          .collection<Communication>('Communication', (f) => {
            return f
              .where('subject.reference', '==', `Patient/${pid}`)
              .where('primaryMedium', '!=', 'INTERNAL')
              .orderBy('primaryMedium')
              .orderBy('sent', 'desc');
          })
          .stateChanges()
          .pipe(
            tap(() => this.queryState$.next(true)),
            takeUntil(this.cancelQueryObservable$.pipe(tap(() => this.queryState$.next(false))))
          );
      }),
      mergeMap((d) => d),
      map((action) => {
        return {
          type: `[Communication] ${action.type}`,
          payload: {
            ...action.payload.doc.data(),
          },
        } as Action;
      }),
      catchError((err) => of(new communicationActions.CommunicationError(err)))
    )
  );
  cancelQuery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(communicationActions.CANCEL_QUERY),
        // tap(() => this.queryComponent$.next()), // do we need to trigger one value?
        switchMap(() => this.queryObservable$.pipe(debounceTime(environment.firestore.unsubscribeDelay))),
        take(1),
        tap(() => this.cancelQueryState$.next())
      ),
    {
      dispatch: false,
    }
  );

  sendCommunication$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(communicationActions.SEND_COMMUNICATION),
      map((action: communicationActions.Send) => action.payload),
      switchMap((payload) =>
        this.service.sendCommunication(payload).pipe(
          map((communication) => new communicationActions.SendSuccess(payload, communication)),
          catchError((err) => {
            new userActions.Check();
            return of(new communicationActions.SendFail(payload, err));
          })
        )
      )
    )
  );

  public sendCommunication(communication: Omit<SendCommunicationRequest, 'tempId' | 'messageMedium' | 'secure'>) {
    this.store.dispatch(
      new communicationActions.Send({
        ...communication,
        tempId: Math.round(Math.random() * 100000 + 100000).toString(10), // temporary random message ID to use for tracking state
        secure: true,
        messageMedium: MessageMedium.portal,
      })
    );
  }

  public cancelQuery() {
    this.store.dispatch(new communicationActions.CancelQuery());
  }

  public select(communication: Communication | null | string) {
    this.store.dispatch(new communicationActions.Select(communication));
  }

  public query() {
    this.store.dispatch(new communicationActions.Query());
  }

  // ************************************************
  // Internal Code
  // ************************************************

  constructor(
    private actions$: Actions,
    private db: AngularFirestore,
    private store: Store<State>,
    private service: CommunicationService,
    private auth: AuthService
  ) {}
}
