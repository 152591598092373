import * as PractitionerActions from './practitioner.actions';
import * as PractitionersModel from './practitioner.model';
import { ActionReducer } from '@ngrx/store';
import { practitionerAdapter } from './practitioner.model';

type State = PractitionersModel.State;
type Actions = PractitionerActions.All;

/// Reducer function
export const practitionerReducer: ActionReducer<State, Actions> = (
  state = PractitionersModel.initialState,
  action
) => {
  switch (action.type) {
    case PractitionerActions.ADDED:
      return practitionerAdapter.addOne(action.payload, { ...state, loading: false });

    case PractitionerActions.SELECT:
      if (!action.practitioner) {
        return state;
      }
      return {
        ...state,
        selectedPractitioner:
          typeof action.practitioner === 'string'
            ? state.entities[action.practitioner]
            : action.practitioner.resourceType === 'Practitioner'
            ? state.entities[action.practitioner.id!]
            : null,
      };
    case PractitionerActions.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
