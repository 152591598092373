import { trigger, transition, animate, style } from '@angular/animations';

export const FADE_IN = trigger('fadeIn', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 })),
    ])
])

export const SLOW_FADE_IN = trigger('slowFadeIn', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.1s', style({ opacity: 1 })),
    ])
])
