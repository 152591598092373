import { Injectable, OnDestroy } from '@angular/core';
import { Campaign, Offer } from '@carecognitics/fhir-resources';
import { DateTime } from 'luxon';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { CommunicationEffect } from '../state/communications';
import { RewardService } from './reward.service';

export type IOffer = Offer & {
  url?: string;
  inlineRedeem?: boolean;
  isOfferCancelled?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class CommunicationHandlerService implements OnDestroy {
  constructor(private effects: CommunicationEffect,
    private rewardService: RewardService) {}

  public communicationQueryCalled = false;

  getCommunications() {
    if (!this.communicationQueryCalled) {
      this.effects.query();
    }
  }

  communicationFetched() {
    this.communicationQueryCalled = true;
  }

  private offer = new BehaviorSubject<Offer[]>([])
  public offers = this.offer.asObservable();
  public offersCalled: boolean = false;

  getOffers() {
    this.rewardService.getActiveCampaigns().subscribe((campaigns: Campaign[])=>{
      const offerUrls: Observable<any>[] = [];
      campaigns.map((campaign: Campaign) => {
        offerUrls.push(this.rewardService.getOffersByCampaignId(campaign.id!));
      });
      if(offerUrls.length){
        forkJoin(offerUrls).subscribe((result) => {
          let allOffers: Offer[] = [];
          offerUrls.map((x, i)=> {
            if(result[i].length)
              allOffers = [...allOffers, ...result[i]]
          })
          allOffers.map((offer: IOffer)=>{
            this.isOfferExpired(campaigns, offer);
          })
          this.offersCalled = true;
          this.offer.next(allOffers);
        });
      }else{
        this.offersCalled = true;
        this.offer.next([])
      }
    })
  }

  isOfferExpired(campaigns: Campaign[], offer: IOffer){
    const index = campaigns.findIndex(i=> `Campaign/${i.id}` === offer.campaign.reference);
    if(index !== -1 && campaigns[index].endDate && this.isOfferCancelled(campaigns[index].endDate)){
      offer.isOfferCancelled = true;
    }else{
      offer.isOfferCancelled = this.isOfferCancelled(offer.expiryDate)
    }
  }

  isOfferCancelled(expiryDate: string | undefined) {
    if(!expiryDate) return false;
    return DateTime.fromISO(expiryDate) < DateTime.now();
  }

  ngOnDestroy(): void {
    this.effects.cancelQuery();
  }
}
