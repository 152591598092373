<mat-progress-bar class="progress-bar" mode="indeterminate" color="accent" *ngIf="readonly && showLoader">
</mat-progress-bar>
<div>
  <div class="p-l-5" *ngIf="readonly">
    <img [src]="practiceLogoUrl" width="60" />
  </div>
  <div *ngIf="!readonly">
    <button mat-icon-button (click)="location.back()">
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
  </div>
  <div>
    <div
      fxLayout="row wrap"
      *ngIf="!drawingSkeleton"
      fxLayout.xs="column-reverse"
      fxLayout.sm="column-reverse"
      fxLayoutAlign="space-between start"
      [ngClass]="readonly ? 'p-l-5' : 'p-l-10'"
      fxFlex
    >
      <div
        fxFlex.gt-sm="45"
        fxFlex="100"
        style="height: 100%; display: flex; justify-content: center; align-items: center"
      >
        <div>
          <div fxLayout="row wrap" class="m-b-20">
            <div class="title">{{ drawingDetails.title }}</div>
          </div>
          <div fxLayout="row wrap">
            <div class="description">{{ drawingDetails.detailedDescription }}</div>
          </div>
        </div>
      </div>
      <div fxFlex.gt-sm="55" fxFlex="100">
        <img [src]="drawingDetails.heroImageURL" class="drawing-img" />
      </div>
    </div>

    <div
      fxLayout="row wrap"
      *ngIf="drawingSkeleton"
      fxLayout.xs="column-reverse"
      fxLayout.sm="column-reverse"
      fxLayoutAlign="space-between start"
      class="m-15"
      fxFlex
    >
      <div fxFlex.gt-sm="44" fxFlex="100">
        <ngx-skeleton-loader count="1" [theme]="{ height: '40px', width: '100%', marginBottom: '0' }">
        </ngx-skeleton-loader>
        <ngx-skeleton-loader count="1" [theme]="{ height: '400px', width: '100%', marginBottom: '0' }">
        </ngx-skeleton-loader>
      </div>
      <div fxFlex.gt-sm="55" fxFlex="100">
        <ngx-skeleton-loader count="1" [theme]="{ height: '445px', marginBottom: '0' }"> </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" *ngIf="winningSkeleton">
    <div class="m-l-15 m-r-15" fxFlex>
      <ngx-skeleton-loader count="1" [theme]="{ height: '400px', marginBottom: '0' }"> </ngx-skeleton-loader>
    </div>
  </div>
  <div fxLayout="row wrap" *ngIf="!winningSkeleton">
    <mat-card
      *ngIf="winners.length"
      class="winner-container"
      fxLayout="row wrap"
      fxFlex="100"
      fxLayoutAlign="center center"
    >
      <mat-card-content fxFlex.gt-sm="60" fxFlex="100">
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h1 style="font-size: 40px; font-weight: 600">Winners</h1>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <h1>{{ drawingDetails.title }} &nbsp;</h1>
          <h1>( {{ drawingDetails.runDate | date }} )</h1>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center center">
          <div>
            <h3>{{ getWinnerTitle(drawingDetails) }}</h3>
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <ng-container *ngFor="let result of winners">
                <div
                  fxFlex.gt-sm="45"
                  fxFlex.gt-md="45"
                  fxFlex.lt-md="45"
                  fxFlex.lt-sm="100"
                  class="winner-ticket-container"
                  style="position: relative"
                >
                  <span style="position: absolute; top: 24px; left: 12px">{{ result.ticketNo }}-1</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card
      *ngIf="drawingDetails.runStatus === 'Executed'"
      class="winner-container"
      fxLayout="row wrap"
      fxFlex="100"
      fxLayoutAlign="center center"
    >
      <mat-card-content>
        <h1>We will update the results soon</h1>
      </mat-card-content>
    </mat-card>
  </div>
  <div fxLayout="row wrap" class="p-l-5 m-b-20">
    <div class="footer-logo" fxFlex.gt-md="30" fxFlex.gt-xs="33" fxFlex="100">
      <img src="assets/images/logo/logo.png" width="60" />
    </div>
    <div class="text-black" fxFlex.gt-md="30" fxFlex.gt-xs="28" fxFlex="100">
      <h3>Contact us</h3>
      hello@carecognitics.com <br />
      <a>+650.617.3425</a>
    </div>
    <div class="text-black" fxFlex.gt-md="40" fxFlex.gt-xs="38" fxFlex="100">
      <h3>Disclaimer</h3>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.
    </div>
  </div>
</div>
