import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunicationRoutingModule } from './communication-routing.module';
import { CommunicationComponent } from './communication.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommunicationEffect, communicationReducer } from '../state/communications';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from '../material.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AvatarModule } from 'ngx-avatar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DetailComponent } from './detail/detail.component';
import { TemplateViewerComponent } from './template-viewer/template-viewer.component';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FileUploadModule } from 'ng2-file-upload';

@NgModule({
  declarations: [
    CommunicationComponent,
    DetailComponent,
    TemplateViewerComponent,
    ChatBubbleComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    TextFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('communication', communicationReducer),
    EffectsModule.forFeature([CommunicationEffect]),
    MaterialModule,
    NgxSkeletonLoaderModule,
    AvatarModule,
    FlexLayoutModule,
    CommunicationRoutingModule,
    ScrollingModule,
    InfiniteScrollModule,
    FileUploadModule
  ],
  exports: [CommunicationComponent, DetailComponent],
})
export class CommunicationModule {}
