import { initialState, schedulesAdapter } from './schedule.model';
import { ADDED, All, CANCEL_QUERY, MODIFIED, QUERY, REMOVED, SELECT, ERROR } from './schedule.actions';

export function schedulesReducer(state = initialState, action: All) {
  switch (action.type) {
    case ADDED:
      return schedulesAdapter.addOne(action.schedule, {
        ...state,
        loading: false,
      });
    case REMOVED:
      return schedulesAdapter.removeOne(action.schedule.id!, { ...state, loading: false });
    case MODIFIED:
      return schedulesAdapter.updateOne(
        {
          id: action.schedule.id!,
          changes: action.schedule,
        },
        { ...state, loading: false }
      );
    case QUERY:
      if (action.scheduleIds?.length && state.selectedSchedule && !(state.selectedSchedule.id! in action.scheduleIds)) {
        return {
          ...state,
          loading: true,
          selectedSchedule: null, // unselect the schedule if the new Ids in the query do not include the current schedule
        };
      } else {
        return { ...state, loading: true };
      }
    case CANCEL_QUERY:
      return {
        ...state,
        loading: false,
      };
    case SELECT:
      if (!action.schedule) {
        return state;
      }
      return {
        ...state,
        selectedSchedule:
          typeof action.schedule === 'string'
            ? state.entities[action.schedule]
            : action.schedule.resourceType === 'Schedule'
            ? state.entities[action.schedule.id!]
            : null,
      };
    case ERROR:
      return { ...state, error: action.error, loading: false };
  }
  return state;
}
