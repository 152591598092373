<div ng2FileDrop 
(fileOver)="fileOverBase($event)" 
(onFileDrop)="onFileDropped()"
[ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
[uploader]="uploader" fxLayout="row" fxLayoutAlign="center start">
  <mat-card fxFlex="100" [ngClass.lt-sm]="{'m-0': true}">
    <mat-card-content [ngClass.lt-sm]="{'p-0':true}">
      <form [ngClass.lt-sm]="{'p-l-20':true, 'p-r-20':true}" (ngSubmit)="sendCommunication(formDirective)" #formDirective="ngForm" [formGroup]="messageForm">
        <div 
          fxLayout="row" 
          fxLayoutAlign="center center">
          <mat-form-field>
            <mat-label>Message</mat-label>
            <input type="text" matInput formControlName="message" placeholder="type your message here...">
          </mat-form-field>
          <input 
            type="file" 
            accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf,audio/*" 
            ng2FileSelect 
            [uploader]="uploader" 
            id="selectedFile" 
            style="display: none;"
            [multiple]="multipleFileSelection"
            (change)="onFileSelected($event)"/>
          <button type="reset" mat-icon-button (click)="onClickUploadFile($event)">
            <mat-icon>attachment</mat-icon>
          </button>
          <div class="m-l-10">
            <button mat-raised-button type="submit" color="primary">Send</button>
          </div>
        </div>
        <div fxLayout="row" *ngIf="uploader.queue.length" class="file-container m-b-10">
          <div *ngFor="let item of uploader.queue">
            <div class="m-r-20" *ngIf="getFileType(item?.file)" fxLayoutAlign="start center">
              <img width="30" *ngIf="getFileType(item?.file)!.type === 'image'" [src]="safeURL(item._file)">
              <img width="30" *ngIf="getFileType(item?.file)!.type !== 'image'" [src]="getFileType(item?.file)!.src">
              <audio controls *ngIf="getFileType(item?.file)!.type === 'audio'" [src]="safeURL(item._file)">
              </audio>
              <span>{{ item?.file?.name }}</span>
              <div class="pointer close-btn" (click)="item.remove()">
                <mat-icon color="warn">close</mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="row">
          <span class="pointer filter m-b-20" fxLayoutAlign="center" (click)="filter()">
            <mat-icon [color]="(attachmentFilter | async)? 'primary' : null">filter_alt</mat-icon>
            <span>{{(attachmentFilter | async)? 'All Communications' : 'Attachments'}}</span>
          </span>
        </div>
      </form>
      <app-chat-bubble></app-chat-bubble>
    </mat-card-content>
  </mat-card>
</div>