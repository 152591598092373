import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { extractId } from '../../../util/helper';

import { DateTime } from 'luxon';
import { FADE_IN } from '../../../util/animations/animation';
import * as fromComm from '../../state/communications';
import {
  Communication,
  selectAllCommunicationsWithTemplate,
  isLoadingCommunications,
  StopLoader,
  NextPage,
  CommunicationPayload,
} from '../../state/communications';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import type { Practitioner } from '@carecognitics/fhir-resources';
import { UserEffects, IUser } from 'src/app/state/user';
import { CommunicationService } from '../../state/communications/communication.service';
import { CommunicationHandlerService } from '../../services/communication-handler.service';
import * as mime from 'mime/lite';
import { ChatService } from '../chat.service';
import { DomSanitizer } from '@angular/platform-browser';

interface Icons extends Record<string, any> {
  EMAIL: {
    icon: string;
    secureIcon: string;
  };
  SMS: {
    icon: string;
    secureIcon: string;
  };
  PORTAL: {
    icon: string;
    secureIcon: string;
  };
}

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
  animations: [FADE_IN],
})
export class ChatBubbleComponent implements OnInit, OnDestroy {
  communications: Communication[] | undefined;
  communicationSubscriber: Subscription;
  DateTime = DateTime;
  loader: boolean;
  loaderSubscriber: Subscription;
  user: Observable<IUser>;
  max = 150;

  practitioners: Practitioner[] = [];
  isPractitionerData = false;

  icons: Icons = {
    EMAIL: {
      icon: 'assets/images/patient-engagement/unsecure-email.png', // 'mail_outline',
      secureIcon: 'assets/images/patient-engagement/secure-email.png',
    },
    SMS: {
      icon: 'assets/images/patient-engagement/insecure-sms.png',
      secureIcon: 'assets/images/patient-engagement/secure-sms.png',
    },
    PORTAL: {
      icon: 'assets/images/patient-engagement/secure-sms.png',
      secureIcon: 'assets/images/patient-engagement/secure-sms.png',
    },
  };

  constructor(
    public chatService: ChatService,
    public auth: AngularFireAuth,
    private store: Store<fromComm.State>,
    private userEffects: UserEffects,
    private communicationService: CommunicationService,
    private communicationHandlerService: CommunicationHandlerService,
    private sanitizer: DomSanitizer
  ) {
    this.user = this.userEffects.user$;
    this.communicationHandlerService.getCommunications();
  }

  ngOnInit(): void {
    this.loaderSubscriber = this.store
      .select(isLoadingCommunications)
      .subscribe((loader: boolean) => (this.loader = loader));
    this.communicationSubscriber = this.store
      .select(selectAllCommunicationsWithTemplate)
      .pipe(
        debounceTime(250),
        map((communications: Communication[]): Communication[] => {
          if (communications.length) {
            //Change status to read
            const ids: string[] = communications
              .filter(
                (i: Communication) =>
                  i.status !== 'completed' && i.sender && i.sender.reference?.startsWith('Practitioner/')
              )
              .map((i: Communication) => <string>i.id);
            if (ids && ids.length) {
              this.communicationService.updateStatus(ids).subscribe();
            }
            return communications.map((communication: Communication) => {
              return { ...communication, extractedData: this.formatCommunication(communication) };
            });
          } else return communications;
        })
      )
      .subscribe((communications: Communication[] | undefined) => {
        this.communications = communications;
        if (
          (this.communicationHandlerService.communicationQueryCalled || (communications && communications.length)) &&
          this.loader
        ) {
          this.store.dispatch(new StopLoader());
          this.communicationHandlerService.communicationFetched();
        }
      });
  }

  formatCommunication(communication: Communication) {
    const isSecure =
      (communication.category &&
        communication.category.length &&
        communication.category[0].coding?.length &&
        communication.category[0].coding[0].code === 'SecureMessage') ||
      false;
    const medium =
      communication.medium?.length && communication.medium[0].coding?.length && communication.medium[0].coding[0].code;
    const key = Object.keys(this.icons).find((x) => medium && medium.replace('_', '') === x) || 'PORTAL';
    const icons = this.icons[key];

    const attachments: CommunicationPayload[] | undefined = communication
    .payload && communication
      .payload.filter((y) => y.contentAttachment)
      .map((x) => {
        const ext = mime.getExtension(x.contentAttachment!.contentType!);
        const fileDetail = this.chatService.fileType.find((y) => y.ext.includes(ext!));
        const retValue: CommunicationPayload = { ...x };
        if ((fileDetail && fileDetail.type === 'image') || (fileDetail && fileDetail.type === 'audio')) {
          retValue.loading = true;
          this.chatService.getFileBlob(communication.id!, x.id!).subscribe((blob: Blob) => {
            retValue.loading = false;
            retValue.blob = blob;
          });
        }
        retValue.fileDetail = fileDetail;
        return retValue;
      });
      
    return {
      secure: isSecure,
      messageMedium: medium || 'PORTAL',
      icon: isSecure ? icons.secureIcon : icons.icon,
      type: communication.sender && communication.sender.reference!.startsWith('Practitioner') ? 'even' : 'odd',
      attachments,
      msg: communication.payload?.find((x) => x.contentString) || '', // to handle empty string bodies for existing messages without payloads or empty contentStrings
      name:
        communication.practitioner &&
        communication.practitioner.name &&
        communication.practitioner.name.length &&
        communication.practitioner.name[0].text,
      isReadMore: true,
    };
  }

  extractSenderAndReceiverIds(data: fromComm.Communication[], type: string) {
    const result: string[] = [];
    data.map((item) => {
      const key = item.sender!.reference!.split('/')[0];
      if (key === type) {
        const id = extractId(item.sender!);
        if (result.findIndex((x) => x === id) === -1) result.push(extractId(item.sender!)!);
      }
    });
    return result;
  }

  trackByMethod(index: number, el: any): number {
    return el.id;
  }

  onReadMore(data: any) {
    data.isReadMore = !data.isReadMore;
  }

  ngOnDestroy(): void {
    this.communicationSubscriber.unsubscribe();
    this.loaderSubscriber.unsubscribe();
  }

  onScroll() {
    this.store.dispatch(new NextPage());
  }
}
