import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AngularFireAuth } from '@angular/fire/auth';
import * as userActions from './user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, distinct, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../state';
import { UsersQuery } from './user.reducer';
import { AuthService } from '@auth0/auth0-angular';
import { from, Observable, of } from 'rxjs';
import { verify } from '../../../util/jwt';
import { environment } from '../../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import pickBy from 'lodash-es/pickBy';
import { IUser } from './user.model';
import { PractitionerService } from '../../services/practitioner.service';

type Action = userActions.All;

const analyticsUserProperties: string[] = ['gender', 'birthdate'];

@Injectable()
export class UserEffects {
  user$ = this.store.select(UsersQuery.getUser);
  userId$ = this.store.pipe(select(UsersQuery.getUid));
  isAuthenticated$ = this.store.select(UsersQuery.isAuthenticated);
  isLoading$ = this.store.select(UsersQuery.isLoading);

  checkAuth$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.Check>(userActions.CHECK),
      switchMap(() => this.authService.user$),
      distinct((user) => user && user.uid),
      switchMap((user) => {
        return this.authService.getAccessTokenSilently().pipe(
          catchError(() => of(null)), // that means we did not have a valid session so we should consider the user logged out
          switchMap((accessToken) =>
            accessToken
              ? user[`${environment.auth0PractitionerConfig.audience}practitioner_id`]
                ? this.practitionerService.getFirebaseToken().pipe(
                    map((response) => response.token),
                    switchMap((accessToken) => this.afAuth.signInWithCustomToken(accessToken)),
                    mapTo(accessToken && user)
                  )
                : from(this.afAuth.signInWithCustomToken(accessToken)).pipe(mapTo(accessToken && user))
              : of(null)
          ),
          switchMap((user) =>
            from(
              this.analytics.setUserProperties(pickBy(user, (value, key) => analyticsUserProperties.includes(key)))
            ).pipe(mapTo(user))
          )
        );
      }),
      map((user) => (user ? new userActions.Authenticated(user as IUser) : new userActions.NotAuthenticated())),
      catchError((error) => of(new userActions.AuthError(error)))
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.Logout>(userActions.LOGOUT),
      switchMap(async () => this.afAuth.signOut()),
      tap(() => this.authService.logout({ returnTo: window.location.origin })),
      map(() => new userActions.NotAuthenticated())
    )
  );

  public login() {
    const sub = this.authService.loginWithPopup().subscribe(() => {
      sub.unsubscribe();
      this.store.dispatch(new userActions.Check());
    });
  }

  public logout() {
    this.store.dispatch(new userActions.Logout());
    return this.isAuthenticated$;
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private analytics: AngularFireAnalytics,
    private practitionerService: PractitionerService
  ) {
    const client = (this.authService as any).auth0Client;
    client._verifyIdToken = (id_token: string, nonce?: string, organizationId?: string) => {
      const isPractitioner = sessionStorage.getItem('ro-login-session') === '2';
      return verify({
        iss: isPractitioner ? environment.auth0PractitionerConfig.issuer : environment.auth0Config.issuer || '',
        aud: isPractitioner ? environment.auth0PractitionerConfig.clientId : environment.auth0Config.audience || '',
        id_token,
        nonce,
        organizationId,
        leeway: client.options.leeway,
        max_age: client._parseNumber(client.options.max_age),
      });
    };
  }
}
