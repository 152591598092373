import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

function handleError(errorResponse: HttpErrorResponse): Observable<never> {
  if (errorResponse.status === 401) {
    return throwError(errorResponse.error);
  } else if (errorResponse.status === 403) {
    return throwError(errorResponse.error.error);
  } else if (errorResponse.status === 400 || errorResponse.status === 500) {
    return throwError(
      errorResponse.error.message || errorResponse.error.error || errorResponse.error || 'Something went wrong'
    );
  } else if (errorResponse.status === 404) {
    return throwError(errorResponse.error.message || 'Server side error');
  } else if (errorResponse.error instanceof ErrorEvent) {
    return throwError(errorResponse.error.message || 'Client side error');
  } else {
    return throwError('Something went wrong');
  }
}

export const catchHttpError = catchError<any, Observable<never>>(handleError);

export const defaultHttpHeaders = new HttpHeaders({ 'content-type': 'application/json', Accept: 'application/json' });

export const defaultHttpOptions = {
  headers: defaultHttpHeaders,
};
