/// <reference types="@types/fhir" />
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getTemplateState, Template, State as TemplateState } from '../templates';
import { extractId } from '../../../util/helper';
import { getPractitionerState, Practitioner, State as PractitionerState } from '../practitioner';
import { basename } from '../../../util/path';
import { FileLikeObject } from 'ng2-file-upload';
import { Communication as CCCommunication } from '@carecognitics/fhir-resources';

export interface Communication extends CCCommunication {
  secure?: boolean;
  template?: Template;
  extractedData?: any;
  practitioner?: Practitioner | null;
  payload?: CommunicationPayload[];
}

export interface CommunicationPayload extends fhir.CommunicationPayload {
  fileDetail?: FileType;
  blob?: Blob | string;
  loading?: boolean;
}

export interface FileType {
  type: string;
  src: string;
  ext: string;
}

export const communicationAdapter = createEntityAdapter<Communication>({
  sortComparer: (c1, c2) => c2.sent!.localeCompare(c1.sent!),
});

export interface State extends EntityState<Communication> {
  loading: boolean;
  error?: any;
  selectedCommunication?: Communication | null;
  pagination: Pagination;
  filterCommunicationByAttachments: boolean;
}

export interface Pagination {
  pageNumber: number;
  totalItems?: number;
  itemsPerPage: number;
}

export enum MessageMedium {
  email = 'EMAIL',
  sms = 'SMS',
  portal = 'PORTAL',
}

export interface SendCommunicationRequest {
  tempId: string;
  recipient_id: string;
  recipient_role: string;
  msg: string;
  messageMedium: MessageMedium;
  secure: boolean;
  dynamicTemplate?: string;
  attachments?: FileLikeObject[];
}

export const initialState: State = communicationAdapter.getInitialState({
  loading: true,
  pagination: {
    pageNumber: 1,
    itemsPerPage: 10,
  },
  filterCommunicationByAttachments: false,
});

export const getCommunicationState = createFeatureSelector<State>('communication');

export const isLoadingCommunications = createSelector(getCommunicationState, (state) => state.loading);

export const getSelectedCommunication = createSelector(
  getCommunicationState,
  getTemplateState,
  (state, templateState) => {
    let selectedCommunication = state.selectedCommunication;
    if (selectedCommunication) {
      selectedCommunication = {
        ...selectedCommunication,
        template: extractTemplateId(templateState, selectedCommunication),
      };
    }
    return selectedCommunication;
  }
);

export const { selectIds, selectAll, selectEntities, selectTotal } =
  communicationAdapter.getSelectors(getCommunicationState);

export const selectAllCommunicationsWithTemplate = createSelector(
  selectAll,
  getCommunicationState,
  getTemplateState,
  getPractitionerState,
  (communications: Communication[], communicationState, templateState, practitionerState) =>
    communications
      .filter(
        (x) =>
          !communicationState.filterCommunicationByAttachments ||
          (x.payload && x.payload.length && x.payload.find((y) => y.contentAttachment))
      )
      .slice(0, communicationState.pagination?.pageNumber * communicationState.pagination?.itemsPerPage)
      .map((app) => {
        return {
          ...app,
          template: extractTemplateId(templateState, app),
          practitioner: selectPractitioner(practitionerState, app),
        };
      })
);

export const extractTemplateId = (templateState: TemplateState, app: Communication): Template | undefined => {
  if (app && app.dynamicTemplate) {
    const id = extractId(app.dynamicTemplate);
    if (id) return templateState.entities[id];
  }
  return undefined;
};

export const selectPractitioner = (
  practitionerState: PractitionerState,
  app: Communication
): Practitioner | undefined => {
  if (app?.sender) {
    const id = extractId(app.sender);
    if (id) return practitionerState.entities[id];
  }
  return undefined;
};

export const recipientId = createSelector(selectAll, (communications: Communication[]) =>
  communications
    .filter((c) => c.sender?.reference?.startsWith('Practitioner'))
    .map((c) => basename(c.sender!.reference!))
    .find((f) => f)
);

export const getAttachmentFilterState = createSelector(
  getCommunicationState,
  (state) => state.filterCommunicationByAttachments
);
