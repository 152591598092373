const scopes: { [key: string]: string[] } = {
  create: ['message', 'appointment'],
  read: ['appointment', 'message', 'template', 'campaign', 'offer', 'drawing', 'reward'],
  update: ['appointment'],
  delete: ['appointment'],
};

export function getScope(): string {
  return Object.keys(scopes)
    .map((s) => scopes[s].reduce((prev, next) => `${prev}${s}:${next} `, ''))
    .reduce((prev, next) => `${prev}${next}`)
    .trim();
}
