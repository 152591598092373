import * as TemplateActions from './template.actions';
import * as TemplateModel from './template.model';
import { ActionReducer } from '@ngrx/store';
import { templateAdapter } from './template.model';

type State = TemplateModel.State;
type Actions = TemplateActions.All;

/// Reducer function
export const templateReducer: ActionReducer<State, Actions> = (
  state = TemplateModel.initialState,
  action
) => {
  switch (action.type) {
    case TemplateActions.ADDED:
      return templateAdapter.addOne(action.payload, { ...state, loading: false });
    case TemplateActions.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
