import { Action } from '@ngrx/store';
import { Appointment } from './appointment.model';
import { HttpErrorResponse } from '@angular/common/http';

export const QUERY = '[Appointment] query';
export const CANCEL_QUERY = '[Appointment] cancel query';
export const SELECT = '[Appointment] select';
export const CREATE = '[Appointment] create';
export const CREATE_SUCCESS = '[Appointment] create success';
export const CREATE_FAIL = '[Appointment] create failed';
export const UPDATE = '[Appointment] update';
export const UPDATE_SUCCESS = '[Appointment] update success';
export const UPDATE_FAIL = '[Appointment] update failed';
export const ADDED = '[Appointment] added';
export const MODIFIED = '[Appointment] modified';
export const REMOVED = '[Appointment] removed';
export const STOP_LOADER = '[Appointment] stop loader';
export const ERROR = '[Appointment] error';

export class Query implements Action {
  readonly type = QUERY;
  constructor(public patientId?: string) {}
}
export class CancelQuery implements Action {
  readonly type = CANCEL_QUERY;
}
export class Select implements Action {
  readonly type = SELECT;
  constructor(public appointment: Appointment | null) {}
}
export class Create implements Action {
  readonly type = CREATE;
  constructor(public appointment: Appointment) {}
}
export class CreateSuccess implements Action {
  readonly type = CREATE_SUCCESS;
  constructor(public toCreate: Appointment, public created: Appointment) {}
}
export class CreateFail implements Action {
  readonly type = CREATE_FAIL;
  constructor(public appointment: Appointment, public error?: HttpErrorResponse | string | null | undefined) {}
}
export class Update implements Action {
  readonly type = UPDATE;
  constructor(public appointment: Partial<Appointment>) {}
}
export class UpdateSuccess implements Action {
  readonly type = UPDATE_SUCCESS;
  constructor(public appointment: Appointment) {}
}
export class UpdateFail implements Action {
  readonly type = UPDATE_FAIL;
  constructor(public error: HttpErrorResponse | string | null | undefined) {}
}
export class Added implements Action {
  readonly type = ADDED;
  constructor(public appointment: Appointment) {}
}
export class Modified implements Action {
  readonly type = MODIFIED;
  constructor(public appointment: Appointment) {}
}
export class Removed implements Action {
  readonly type = REMOVED;
  constructor(public appointment: Appointment) {}
}
export class StopLoader implements Action {
  readonly type = STOP_LOADER;
}
export class AppointmentError implements Action {
  readonly type = ERROR;
  constructor(public error: any) {}
}
export type All =
  | Create
  | CreateSuccess
  | CreateFail
  | Update
  | UpdateSuccess
  | UpdateFail
  | Query
  | CancelQuery
  | Select
  | Added
  | Modified
  | Removed
  | StopLoader
  | AppointmentError;
