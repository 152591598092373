import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service';
import { FADE_IN } from '../../../util/animations/animation';
import { RewardService } from 'src/app/services/reward.service';
import { Drawing, DrawingAwardRule } from '@carecognitics/fhir-resources';
import { Location } from '@angular/common';

/**
 * This component is used to show the winning tickets and for the particular drawing
 */
@Component({
  selector: 'app-winning-page',
  templateUrl: './winning-page.component.html',
  styleUrls: ['./winning-page.component.scss'],
  animations: [FADE_IN],
})
export class WinningPageComponent implements OnInit {
  readonly: boolean = window.location.href.includes('/ro/');
  showLoader = false;
  accessToken = '';
  practiceLogoUrl: string = environment.tenant.logo;
  drawingId: string | null;
  drawingDetails: any = {};
  awardRuleDeatils: DrawingAwardRule;
  winners: any = [];
  rewards = [];
  ticketBox: any = {
    loaderMsg: '',
    successMsg: '',
    failMsg: '',
  };
  drawingSkeleton = true;
  winningSkeleton = true;

  constructor(
    private rewardService: RewardService,
    private activeRoute: ActivatedRoute,
    private loader: LoaderService,
    public location: Location
  ) {}

  ngOnInit() {
    this.loader.progressLoader.subscribe((state) => {
      this.showLoader = state;
    });
    this.drawingId = this.activeRoute.snapshot.paramMap.get('id');
    if (this.drawingId) {
      this.getDrawing(this.drawingId);
    } else {
      this.loader.hide('progress');
    }
  }

  /**
   * This function is used to get the drawing details by using drawing Id
   * @param drawingId
   */
  getDrawing(drawingId: string) {
    this.drawingSkeleton = true;
    this.rewardService.getDrawingById(drawingId).subscribe(
      (response: any) => {
        this.drawingDetails = response;
        this.awardRuleDeatils = this.drawingDetails.awardRule[0];

        if (this.drawingDetails.runStatus === 'UsersNotified' || this.drawingDetails.runStatus === 'Users Notified') {
          this.getWinningTickets();
        } else {
          console.log('the winner is not declared yet');
          this.winningSkeleton = false;
        }

        this.loader.hide('progress');
        this.drawingSkeleton = false;
      },
      () => {
        this.loader.hide('progress');
        this.drawingSkeleton = false;
      }
    );
  }

  /**
   * This function is used to get the winning tickets for particular drawing
   */
  getWinningTickets() {
    if (this.drawingId) {
      this.winningSkeleton = true;
      this.loader.show('progress');
      this.rewardService.getWinnigDrawingReward(this.drawingId).subscribe(
        (response: any) => {
          this.winners = response;
          this.loader.hide('progress');
          this.winningSkeleton = false;
        },
        () => {
          this.loader.hide('progress');
          this.winningSkeleton = false;
        }
      );
    }
  }

  /**
   * This function is used to create the winner title
   * @param drawing
   */
  getWinnerTitle(drawing: Drawing) {
    const singular = drawing.numberOfWinners > 1 ? 'are' : 'the';
    return `Congratulations to ${singular} ${drawing.numberOfWinners} lucky winners out of ${drawing.numberOfTickets} tickets and ${drawing.numberOfParticipants} participants.`;
  }
}
