import { Action } from '@ngrx/store';
import { Reward } from '@carecognitics/fhir-resources';

export const QUERY = '[Reward] query';
export const CANCEL_QUERY = '[Reward] cancel query';
export const SELECT = '[Reward] select';
export const STOP_LOADER = '[Reward] stop loader';
export const ERROR = '[Reward] error';

export class Query implements Action {
  readonly type = QUERY;
  constructor(public patientId?: string) {}
}
export class CancelQuery implements Action {
  readonly type = CANCEL_QUERY;
}
export class Select implements Action {
  readonly type = SELECT;
  constructor(public reward: Reward | null) {}
}

export class StopLoader implements Action {
  readonly type = STOP_LOADER;
}
export class RewardError implements Action {
  readonly type = ERROR;
  constructor(public error: any) {}
}
export type All = Query | CancelQuery | Select | StopLoader | RewardError;
