import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanLoad, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { take, switchMap, mapTo, skip } from 'rxjs/operators';
import { UserEffects } from './state/user';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private user: UserEffects, private auth: AuthService) {}

  canLoad(): Observable<boolean> {
    return this.user.isAuthenticated$.pipe(take(1));
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.redirectIfUnauthenticated(state);
  }

  private redirectIfUnauthenticated(state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      switchMap((loggedIn) => {
        if (!loggedIn) {
          const login_hint = sessionStorage.getItem('login_hint') || '';
          const pid = sessionStorage.getItem('pid');
          const options:any = { appState: { target: state.url }, pid };
          if(login_hint){
            options.login_hint = login_hint;
          }
          return this.auth
            .loginWithRedirect(options)
            .pipe(mapTo(true));
        } else {
          return this.user.isLoading$.pipe(
            switchMap((loading)=>{
              if(!loading){
                return this.user.isAuthenticated$.pipe(take(1));
              }else{
                return this.user.isAuthenticated$.pipe(skip(1));
              }
            })
          )
          
        }
      })
    );
  }
}
