/// <reference types="@types/fhir" />
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface Practitioner extends fhir.Practitioner {

}

export const practitionerAdapter = createEntityAdapter<Practitioner>();

export interface State extends EntityState<Practitioner> {
  loading: boolean;
  error?: any;
  selectedPractitioner?: Practitioner | null;
}

export const initialState: State = practitionerAdapter.getInitialState({ loading: true });

export const getPractitionerState = createFeatureSelector<State>('practitioner');

export const isLoadingPractitioner = createSelector(getPractitionerState, (state) => state.loading);
export const getSelectedPractitioner = createSelector(getPractitionerState, (state) => state.selectedPractitioner);

export const { selectIds, selectAll, selectEntities, selectTotal } = practitionerAdapter.getSelectors(
  getPractitionerState
);