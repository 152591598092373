import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { UserEffects } from './state/user';

@Injectable({
  providedIn: 'root',
})
export class LogoutGuard implements CanActivate {
  constructor(private user: UserEffects) {}

  canActivate(): Observable<boolean> {
    return this.user.logout();
  }
}
