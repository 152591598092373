import { Action } from '@ngrx/store';
import { Schedule } from '@carecognitics/fhir-resources';
import { AppointmentError } from '../appointment';

export const QUERY = '[Schedule] query';
export const CANCEL_QUERY = '[Schedule] cancel query';
export const SELECT = '[Schedule] select';
export const ADDED = '[Schedule] added';
export const MODIFIED = '[Schedule] modified';
export const REMOVED = '[Schedule] removed';
export const ERROR = '[Schedule] error';

export class Query implements Action {
  readonly type = QUERY;
  constructor(public scheduleIds?: string[]) {} // if scheduleIds are specified, only include schedules with the given IDs in the results
}
export class CancelQuery implements Action {
  readonly type = CANCEL_QUERY;
}
export class Added implements Action {
  readonly type = ADDED;
  constructor(public schedule: Schedule) {}
}
export class Modified implements Action {
  readonly type = MODIFIED;
  constructor(public schedule: Schedule) {}
}
export class Removed implements Action {
  readonly type = REMOVED;
  constructor(public schedule: Schedule) {}
}
export class Select implements Action {
  readonly type = SELECT;
  constructor(public schedule: Schedule | string | null) {}
}
export class ScheduleError implements Action {
  readonly type = ERROR;
  constructor(public error: any) {}
}
export type All = Query | CancelQuery | Added | Modified | Removed | Select | AppointmentError | ScheduleError;
