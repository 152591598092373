import { Action } from '@ngrx/store';
import { Communication, SendCommunicationRequest, Pagination } from './communication.model';

export const QUERY = '[Communication] query';
export const CANCEL_QUERY = '[Communication] cancel query';
export const ADDED = '[Communication] added';
export const MODIFIED = '[Communication] modified';
export const REMOVED = '[Communication] removed';
export const SELECT = '[Communication] select';
export const STOP_LOADER = '[Communication] stop loader';
export const NEXT_PAGE = '[Communication] next page';
export const SEND_COMMUNICATION = '[Communication] send';
export const SEND_COMMUNICATION_SUCCESS = '[Communication] send success';
export const SEND_COMMUNICATION_FAIL = '[Communication] send fail';
export const ERROR = '[Communication] error';
export const ATTACHMENT_FILTER = '[Communication] Attachment filter';

export class Query implements Action {
  readonly type = QUERY;
  constructor(public payload?: any) {}
}

export class CancelQuery implements Action {
  readonly type = CANCEL_QUERY;
  constructor(public payload?: any) {}
}

export class Added implements Action {
  readonly type = ADDED;
  constructor(public payload: Communication) {}
}

export class Modified implements Action {
  readonly type = MODIFIED;
  constructor(public payload: Communication) {}
}

export class Removed implements Action {
  readonly type = REMOVED;
  constructor(public payload: Communication) {}
}

export class Send implements Action {
  readonly type = SEND_COMMUNICATION;
  constructor(public payload: SendCommunicationRequest) {}
}

export class SendSuccess implements Action {
  readonly type = SEND_COMMUNICATION_SUCCESS;
  constructor(public request: SendCommunicationRequest, public payload: Communication) {}
}

export class SendFail implements Action {
  readonly type = SEND_COMMUNICATION_FAIL;
  constructor(public request: SendCommunicationRequest, public error: any) {}
}

export class Select implements Action {
  readonly type = SELECT;
  constructor(public communication: Communication | string | null) {}
}

export class StopLoader implements Action {
  readonly type = STOP_LOADER;
}

export class NextPage implements Action {
  readonly type = NEXT_PAGE;
}

export class CommunicationError implements Action {
  readonly type = ERROR;
  constructor(public error: any) {}
}

export class AttachmentFilter implements Action {
  readonly type = ATTACHMENT_FILTER;
}

export type All =
  | Query
  | CancelQuery
  | Added
  | Modified
  | Removed
  | Send
  | SendSuccess
  | SendFail
  | Select
  | StopLoader
  | CommunicationError
  | NextPage
  | AttachmentFilter;
