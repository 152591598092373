<div class="main-container">
  <!--<mat-toolbar color="primary" class="topbar telative">
     <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="navbar-header">
      <a class="navbar-brand" href="/">
        <b>
          <img width="250" src="assets/images/logo/mycareplan.png" alt="homepage" class="light-logo" />
        </b>
      </a>
    </div>
    <span fxFlex></span>
    <button mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
  </mat-toolbar> -->
  <div class="header">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start">
      <div fxLayout="row">
        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="title">
          <img height="27" [src]="logo" alt="homepage" class="light-logo" />
        </div>
      </div>
      <button mat-icon-button (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>

  <mat-sidenav-container class="layout-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <mat-sidenav
      #snav
      id="snav"
      class="pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="true"
      [disableClose]="mobileQuery.matches"
      (open)="sidebarOpened = true"
      (close)="sidebarOpened = false"
      [perfectScrollbar]="config"
    >
      <app-sidebar class="app-sidebar" (onClick)="onClickMenuOption()"></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content id="page-wrapper" class="page-wrapper">
      <div class="page-content">
        <div class="router-container">
          <router-outlet>
            <!-- <app-spinner></app-spinner> -->
          </router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
