import { appointmentAdapter, initialState } from './appointment.model';
import {
  QUERY,
  CANCEL_QUERY,
  ADDED,
  All,
  CREATE,
  CREATE_FAIL,
  CREATE_SUCCESS,
  MODIFIED,
  REMOVED,
  UPDATE,
  UPDATE_FAIL,
  UPDATE_SUCCESS,
  STOP_LOADER,
  ERROR,
} from './appointment.actions';

export function appointmentsReducer(state = initialState, action: All) {
  switch (action.type) {
    case QUERY:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_QUERY:
      return {
        ...state,
        entities: {},
        ids: [],
        loading: false,
      };
    case ADDED:
      return appointmentAdapter.addOne(action.appointment, {
        ...state,
        loading: false,
      });
    case REMOVED:
      return appointmentAdapter.removeOne(action.appointment.id!, { ...state, loading: false });
    case MODIFIED:
      return appointmentAdapter.updateOne(
        {
          id: action.appointment.id!,
          changes: action.appointment,
        },
        { ...state, loading: false }
      );
    case CREATE:
      return appointmentAdapter.addOne(action.appointment, {
        ...state,
        error: null,
        loading: true,
      });
    case CREATE_SUCCESS:
      return appointmentAdapter.updateOne(
        {
          id: action.toCreate.id!,
          changes: action.created,
        },
        {
          ...state,
          error: null,
          loading: false,
        }
      );
    case CREATE_FAIL:
      return appointmentAdapter.removeOne(action.appointment.id!, {
        ...state,
        error: action.error,
        loading: false,
      });
    case UPDATE:
      return appointmentAdapter.updateOne(
        {
          id: action.appointment.id!,
          changes: action.appointment, // update the state to what we're expecting it to be
        },
        {
          ...state,
          beforeUpdate: state.entities[action.appointment.id!], // store the state before so we can roll back on error
          error: null,
          loading: true,
        }
      );
    case UPDATE_SUCCESS:
      // finalize the update in case there are additional fields returned
      return appointmentAdapter.updateOne(
        {
          id: action.appointment.id!,
          changes: action.appointment,
        },
        {
          ...state,
          beforeUpdate: null,
          error: null,
          loading: false,
        }
      );
    case UPDATE_FAIL:
      // rollback state on failure
      // eslint-disable-next-line no-case-declarations
      const stateAfter = {
        ...state,
        error: action.error,
        beforeUpdate: null,
        loading: false,
      };
      if (state.beforeUpdate) {
        // just in case we had other actions that cleared the state
        return appointmentAdapter.setOne(state.beforeUpdate, stateAfter);
      } else {
        return stateAfter;
      }
    case STOP_LOADER:
      return {
        ...state,
        loading: false,
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
  }
  return state;
}
