import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserEffects } from './state/user';
import { Store } from '@ngrx/store';
import { AppState } from './state/state';
import * as userActions from './state/user/user.actions';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TemplateEffect } from './state/templates';
import { PractitionerEffects } from './state/practitioner';

@Component({
  selector: 'cc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'mycareplan';
  authUserSubscriber: Subscription;

  constructor(public auth: UserEffects,
    private router: Router,
    private templateEffect: TemplateEffect,
    private practitionerEffects: PractitionerEffects,
    private store: Store<AppState>) {
      this.parseParams();

      this.authUserSubscriber = this.auth.user$.pipe(tap((user)=>{
        let url = sessionStorage.getItem('deeplink');
        if(url){
          sessionStorage.removeItem('deeplink');
          this.router.navigateByUrl(url).catch(error => {
            console.error("Error navigating to deep link" + error)
          });
        }
        if(user && user.uid){
          this.practitionerEffects.query();
          this.templateEffect.query();
        }
      })).subscribe();
  }

  parseParams(){
    const urlParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem('pid', urlParams.get('pid') || '');
    if(urlParams.get('email_addr')){
      sessionStorage.setItem('login_hint', urlParams.get('email_addr') || '');
    }else if(urlParams.get('phone_number')){
      sessionStorage.setItem('login_hint', urlParams.get('phone_number') || '');
    }
    let url = sessionStorage.getItem('deeplink');
    if(!url){
      url = window.location.href;
      if(url.includes('/app/')){
        url = `/app${url.split('/app').pop()}`;
        sessionStorage.setItem('deeplink', url);
      }
    }
  }

  ngOnInit(){
    this.store.dispatch(new userActions.Check());
  }

  loginWithRedirect(): void {
    // Call this to redirect the user to the login page
    this.auth.login();
  }

  ngOnDestroy(): void {
    this.authUserSubscriber.unsubscribe();
    this.templateEffect.cancelQuery();
    this.practitionerEffects.cancelQuery();
  }
}
