import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import * as rewardActions from './reward.actions';
import { Reward } from '@carecognitics/fhir-resources';
import { AppState } from '../state';
import 'firebase/firestore';
import { RewardService } from '../../services/reward.service';

export const RewardQuery = {
  isLoading: (state: AppState) => state.reward.loading,
  error: (state: AppState) => state.reward.error,
};

@Injectable()
export class RewardEffects {
  error$ = this.store.select(RewardQuery.error);
  isLoading$ = this.store.select(RewardQuery.isLoading);

  private cancelQueryState$ = new Subject<void>();
  private cancelQueryObservable$ = this.cancelQueryState$.asObservable();
  private queryState$ = new Subject<boolean>();
  private queryObservable$ = this.queryState$.asObservable();

  public cancelQuery() {
    this.store.dispatch(new rewardActions.CancelQuery());
  }

  public query(patientId?: string) {
    this.store.dispatch(new rewardActions.Query(patientId));
  }

  public select(reward: Reward | null) {
    this.store.dispatch(new rewardActions.Select(reward));
  }

  constructor(
    private actions$: Actions,
    private db: AngularFirestore,
    private store: Store<AppState>,
    private service: RewardService
  ) {}
}
