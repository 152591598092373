import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { roleSettings } from 'src/util/role-settings';
import { environment } from 'src/environments/environment';
import { MenuItems } from 'src/util/menu-items';
import { IUser, UserEffects } from 'src/app/state/user';

export interface SelectedRoute {
  route: string;
  selected: boolean;
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnDestroy {
  @Output() onClick = new EventEmitter();
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  sideBarSettings: any = roleSettings.sideMenu;
  logo: string = 'assets/images/logo/profile-avatar.png';//environment.tenant.logo;
  sideMenuItems: any = [];
  private _mobileQueryListener: () => void;
  status = false;
  selectedOptionRoute: SelectedRoute = {
    route: '',
    selected: false,
  };
  userDetails: IUser

  clickEvent() {
    this.onClickMenuOption();
    this.status = !this.status;
  }

  subclickEvent() {
    this.status = true;
  }

  constructor(public auth: UserEffects, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public menuItems: MenuItems) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.sideMenuItems = this.menuItems.getMenuitem();
    this.auth.user$.subscribe(user=>{
      this.userDetails = user;
      console.log("user", user)
    })
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  selectedOption(e: SelectedRoute) {
    if (this.selectedOptionRoute.route !== e.route && e.selected) {
      setTimeout(() => (this.selectedOptionRoute = e));
    } else if (this.selectedOptionRoute.route === e.route && this.selectedOptionRoute.selected !== e.selected) {
      setTimeout(() => (this.selectedOptionRoute = e));
    }
  }

  onClickMenuOption(){
    this.onClick.emit('click');
  }
}
