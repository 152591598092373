import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounce,
  debounceTime,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Template, State } from './template.model';

import * as templateActions from './template.actions';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';

type Action = templateActions.All;

@Injectable()
export class TemplateEffect {
  private cancelQueryState$ = new Subject<void>();
  private cancelQueryObservable$ = this.cancelQueryState$.asObservable();
  private queryState$ = new Subject<boolean>();
  private queryObservable$ = this.cancelQueryState$.asObservable();
  query$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<templateActions.Query>(templateActions.QUERY),
      withLatestFrom(this.auth.user$),
      switchMap(() => {
        return this.db
          .collection<Template>('DynamicTemplate', (f) => {
            return f.where("templateType", "!=", "printTemplate");
          })
          .stateChanges()
          .pipe(
            tap(() => this.queryState$.next(true)),
            takeUntil(this.cancelQueryObservable$.pipe(tap(() => this.queryState$.next(false))))
          );
      }),
      mergeMap((d) => d),
      map((action) => {
        return {
          type: `[Template] ${action.type}`,
          payload: {
            ...action.payload.doc.data(),
          },
        } as Action;
      }),
      catchError((err) => of(new templateActions.TemplateError(err)))
    )
  );
  cancelQuery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(templateActions.CANCEL_QUERY),
        switchMap(() => this.queryObservable$.pipe(debounceTime(environment.firestore.unsubscribeDelay))),
        take(1),
        tap(() => this.cancelQueryState$.next())
      ),
    {
      dispatch: false,
    }
  );

  public cancelQuery() {
    this.store.dispatch(new templateActions.CancelQuery());
  }

  public query() {
    this.store.dispatch(new templateActions.Query());
  }

  // ************************************************
  // Internal Code
  // ************************************************

  constructor(
    private actions$: Actions,
    private db: AngularFirestore,
    private store: Store<State>,
    private auth: AuthService
  ) {}
}
