import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';
import { AppState } from 'src/app/state';
import { 
  Communication, 
  CommunicationEffect, 
  getSelectedCommunication, 
  isLoadingCommunications, 
  selectAllCommunicationsWithTemplate, 
  StopLoader,
  CommunicationPayload } from '../../state/communications';
import { Router } from '@angular/router';
import { CommunicationHandlerService } from 'src/app/services/communication-handler.service';
import { CommunicationService } from '../../state/communications/communication.service';
import { ChatService } from '../chat.service';
import * as mime from 'mime/lite';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  communication: Observable<Communication | null | undefined>;
  communicationId: string;
  loader: boolean;
  loaderSubscriber: Subscription;
  communicationSubscriber: Subscription;

  constructor(private route: ActivatedRoute,
    public chatService: ChatService,
    private store: Store<AppState>,
    private router: Router,
    private communicationService: CommunicationService,
    private communicationHandlerService: CommunicationHandlerService,
    private effects: CommunicationEffect) {
      this.loaderSubscriber = this.store.select(isLoadingCommunications).subscribe((loader: boolean)=> this.loader = loader);
      this.communicationHandlerService.getCommunications();
    this.route.params.subscribe(params => {
      this.communicationId = params.id;
    });
    this.communicationSubscriber = this.store
      .select(selectAllCommunicationsWithTemplate)
      .pipe(debounceTime(500)
    ).subscribe((communications)=>{
      if ((this.communicationHandlerService.communicationQueryCalled || communications.length) && this.loader) {
        this.store.dispatch(new StopLoader);
        this.effects.select(this.communicationId);
      }
    })
  }


  ngOnInit() {
    this.communication = this.store.select(getSelectedCommunication)
    .pipe(
      take(1),
      map((communication) => this.formatCommunication(communication)),
      tap((communication: Communication)=>{
        if(communication!.status === 'in-progress' && communication!.sender && communication!.sender.reference?.startsWith('Practitioner/')){
          this.communicationService.updateStatus([communication!.id!]).subscribe()
        }
      })
    )
  }

  ngOnDestroy() {
    if(this.communicationSubscriber){
      this.communicationSubscriber.unsubscribe();
    }
    this.loaderSubscriber.unsubscribe();
  }

  allCommunications() {
    this.router.navigateByUrl('app/communication');
  }

  formatCommunication(communication: Communication | undefined | null): Communication {
    if(communication){
      const attachments: CommunicationPayload[] = communication
        .payload!.filter((y) => y.contentAttachment)
        .map((x) => {
          const ext = mime.getExtension(x.contentAttachment!.contentType!);
          const fileDetail = this.chatService.fileType.find((y) => y.ext.includes(ext!));
          const retValue: CommunicationPayload = { ...x };
          if (fileDetail && fileDetail.type === 'image') {
            retValue.loading = true;
            this.chatService.getFileBlob(communication.id!, x.id!).subscribe((blob: Blob) => {
              retValue.loading = false;
              retValue.blob = blob;
            });
          }
          retValue.fileDetail = fileDetail;
          return retValue;
      });
      communication.extractedData = {
        attachments,
        msg: communication.payload!.find((x) => x.contentString)
      }
    }
    return communication as Communication;
  }
}