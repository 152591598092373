import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommunicationV1RoutingModule } from './communication-v1-routing.module';
import { ChatContainerComponent } from './components/chat-container/chat-container.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachmentPopupComponent } from './components/attachment-popup/attachment-popup.component';
import { StoreModule } from '@ngrx/store';
import { CommunicationEffect, communicationReducer } from '../state/communications';
import { EffectsModule } from '@ngrx/effects';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AvatarModule } from 'ngx-avatar';
import { CommunicationRoutingModule } from '../communication/communication-routing.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SafeHtmlPipe } from 'src/util/pipes/safe-html.pipe';
import { PopupContainerComponent } from './components/popup-container/popup-container.component';
import { TemplateViewerComponent } from './components/template-viewer/template-viewer.component';
import { DetailComponent } from './components/detail/detail.component';

@NgModule({
  declarations: [
    ChatContainerComponent,
    AttachmentPopupComponent,
    SafeHtmlPipe,
    PopupContainerComponent,
    TemplateViewerComponent,
    DetailComponent
  ],
  imports: [
    CommonModule,
    CommunicationV1RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('communication', communicationReducer),
    EffectsModule.forFeature([CommunicationEffect]),
    MaterialModule,
    NgxSkeletonLoaderModule,
    AvatarModule,
    FlexLayoutModule,
    CommunicationRoutingModule,
    ScrollingModule,
    InfiniteScrollModule,
    FileUploadModule
  ],
  providers: [
    SafeHtmlPipe
  ]
})
export class CommunicationV1Module { }
