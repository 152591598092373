/* eslint-disable @typescript-eslint/ban-ts-comment, no-constant-condition */
import { CacheLocation } from '@auth0/auth0-spa-js';

export const environment = {
  // @ts-ignore
  production: 'qa' === 'production',
  auth0Config: {
    clientId: 'UiGkMexB8Dy7mgqqo8VS4LABAdS9nGJ8as9mNJLl3n4',
    domain: 'patient-auth-service.onehealth.test.mycareplan.health',
    redirectUri: 'https://onehealth.test.mycareplan.health',
    audience: 'https://identitytoolkit.googleapis.com/google.identity.identitytoolkit.v1.IdentityToolkit',
    issuer: 'patient-auth-service@cc-deploy-onehealth-dev.iam.gserviceaccount.com',
    // @ts-ignore
    // ...('' !== '' ? { scope: '' } : {}),
    // @ts-ignore
    ...('' !== '' ? { cacheLocation: '' as CacheLocation } : {}),
  },
  auth0PractitionerConfig: {
    clientId: 'WqqwttN8FRwr0rMF9PT18LzjEB0E0cOp',
    domain: 'auth.onehealth.test.carecognitics.health',
    responseType: 'code',
    redirectUri: 'https://onehealth.test.mycareplan.health',
    audience: 'https://onehealth.test.carecognitics.health/api/v1/',
    issuer: 'https://auth.onehealth.test.carecognitics.health/',
    scope:
      'openid email profile read:current_user firebase read:reward read:offer read:drawing read:patient read:practitioner read:campaign read:drawingResult read:rule read:message create:message read:template read:location read:schedule read:appointment',
  },
  readOnlyClientId: 'bUb45z5H4m--oe0JaRWNp1Z9sw3xuzqhp3jx1VGk5oM',
  firebaseConfig: {
    apiKey: 'AIzaSyDSheQDE33m1ACWWH93VC8fUn1RN3cLTCw',
    authDomain: 'cc-deploy-onehealth-dev.firebaseapp.com',
    databaseURL: 'https://cc-deploy-onehealth-dev.firebaseio.com',
    projectId: 'cc-deploy-onehealth-dev',
    storageBucket: 'cc-deploy-onehealth-dev.appspot.com',
    messagingSenderId: '71657680916',
    appId: '1:71657680916:web:2851f6a9042fceb7773701',
    measurementId: 'G-8ND350RR7Z',
  },
  serviceBaseHostname: 'onehealth.test.mycareplan.health',
  services: {
    communicationService: 'https://communication-service.onehealth.test.mycareplan.health',
    patientAuthService: 'https://patient-auth-service.onehealth.test.mycareplan.health',
    appointment: 'https://appointment-service.onehealth.test.mycareplan.health',
    dashboard: 'https://dashboard-service.onehealth.test.mycareplan.health',
    campaign: 'https://reward-service.onehealth.test.mycareplan.health/',
    offer: 'https://reward-service.onehealth.test.mycareplan.health/',
    reward: 'https://reward-service.onehealth.test.mycareplan.health/',
    drawing: 'https://reward-service.onehealth.test.mycareplan.health/',
    practitioner: 'https://practitioner-service.onehealth.test.mycareplan.health',
  },
  firestore: {
    unsubscribeDelay: 30000,
  },
  tenant: {
    name: '',
    logo: 'https://www.carecognitics.com/wp-content/uploads/2022/05/icon.png',
  },
  imgUrl: 'https://app.test.mycareplan.health/assets/images/',
  drawingWinningPageUrl: 'https://onehealth.test.mycareally.health/winners',
  rewardType: 'Ticket',
};

import 'zone.js/plugins/zone-error';
