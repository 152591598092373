import { Action } from '@ngrx/store';
import { Slot } from '@carecognitics/fhir-resources';

export const QUERY = '[Slot] query';
export const CANCEL_QUERY = '[Slot] cancel query';
export const SELECT = '[Slot] select';
export const ADDED = '[Slot] added';
export const MODIFIED = '[Slot] modified';
export const REMOVED = '[Slot] removed';

export class Query implements Action {
  readonly type = QUERY;
  constructor(public scheduleId?: string) {}
}
export class CancelQuery implements Action {
  readonly type = CANCEL_QUERY;
}
export class Added implements Action {
  readonly type = ADDED;
  constructor(public slot: Slot) {}
}
export class Modified implements Action {
  readonly type = MODIFIED;
  constructor(public slot: Slot) {}
}
export class Removed implements Action {
  readonly type = REMOVED;
  constructor(public slot: Slot) {}
}
export class Select implements Action {
  readonly type = SELECT;
  constructor(public slot: Slot | null) {}
}

export type All = Query | CancelQuery | Added | Modified | Removed | Select;
