import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FADE_IN } from '../../../../util/animations/animation';

@Component({
  selector: 'app-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
  animations: [FADE_IN],
})
export class PopupContainerComponent {

  constructor(public dialogRef: MatDialogRef<PopupContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public popup: any, @Inject(MAT_DIALOG_DATA) public data: any) { }


  onClosePopup(){
    this.dialogRef.close();
  }

}
