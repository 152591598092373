import { Reward } from '@carecognitics/fhir-resources';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../state';
import { HttpErrorResponse } from '@angular/common/http';

export const appointmentFeatureKey = 'appointment';

export interface State extends EntityState<Reward> {
  loading: boolean;
  error: HttpErrorResponse | string | null | undefined;
  beforeUpdate?: Reward | null | undefined;
}

export const appointmentAdapter = createEntityAdapter<Reward>();

export const initialState: State = appointmentAdapter.getInitialState({
  loading: false,
  error: null,
  beforeUpdate: null,
});

export const getAppointmentsState = createFeatureSelector<AppState, State>(appointmentFeatureKey);
export const getSelectedAppointment = createSelector(getAppointmentsState, (state) => state);

export const {
  selectIds,
  selectAll: selectAllAppointments,
  selectEntities,
  selectTotal,
} = appointmentAdapter.getSelectors(getAppointmentsState);
