import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { CommunicationPayload } from '../state/communications';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private sanitizer: DomSanitizer, private toastr: ToastrService, private http: HttpClient) {}

  fileType = [
    { type: 'image', src: '', ext: 'png jpg jpeg tif gif svg' },
    { type: 'pdf', src: 'assets/images/documents/pdf.png', ext: 'pdf' },
    { type: 'doc', src: 'assets/images/documents/doc.png', ext: 'doc docx' },
    { type: 'excel', src: 'assets/images/documents/excel.png', ext: 'xls xlsb xlsm xlsx csv' },
    { type: 'audio', src: '', ext: 'mp3 m4a flac wav wma aac mpeg mpga' }
  ];

  getFileBlob(messageId: string, attachmentId: string): Observable<Blob> {
    return this.http.get(
      `${environment.services.communicationService}/message/${messageId}/attachment/${attachmentId}`,
      { responseType: 'blob' }
    );
  }

  downloadFile(messageId: string, attachment: CommunicationPayload) {
    if (attachment && attachment.blob) {
      this.download(attachment.contentAttachment!.title!, attachment.blob);
    } else if (messageId && attachment.id) {
      this.getFileBlob(messageId, attachment.id).subscribe(
        (blob: Blob) => {
          this.download(attachment.contentAttachment!.title!, blob);
        },
        (error) => {
          this.toastr.warning(error, 'download', {
            progressBar: true,
            timeOut: 3000,
          });
        }
      );
    }
  }

  download(filename: string, blob: any) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  safeURL(blob: Blob): SafeUrl | null {
    if(blob) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    } else return null
  }
}
