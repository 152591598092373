import { Action } from '@ngrx/store';
import { IUser } from './user.model';

export const AUTHENTICATED = '[Auth] Authenticated';
export const NOT_AUTHENTICATED = '[Auth] Not Authenticated';
export const LOGOUT = '[Auth] Logout';
export const AUTH_ERROR = '[Auth] Error';
export const LOGIN = '[Auth] Login';
export const CHECK = '[Auth] check';
export const STOP_LOADER = '[Auth] Stop Loader';

/// Get User AuthState
export class Authenticated implements Action {
  readonly type = AUTHENTICATED;
  constructor(public payload: IUser) {}
}

export class NotAuthenticated implements Action {
  readonly type = NOT_AUTHENTICATED;
  constructor(public payload?: any) {}
}

export class AuthError implements Action {
  readonly type = AUTH_ERROR;
  constructor(public payload?: any) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
  constructor(public payload?: any) {}
}

export class Login implements Action {
  readonly type = LOGIN;
}

export class Check implements Action {
  readonly type = CHECK;
}

export class StopLoader implements Action {
  readonly type = STOP_LOADER;
}

export type All = Authenticated | NotAuthenticated | AuthError | Logout | Login | Check | StopLoader;
