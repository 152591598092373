import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { catchHttpError } from '../../util/http';
import { UserEffects } from '../state/user';
@Injectable({
  providedIn: 'root',
})
export class RewardService {
  constructor(private http: HttpClient, private auth: UserEffects) {}

  get isPractitioner() {
    const a = this.auth.user$.pipe(
      take(1),
      tap((user: any) => {
        return !_.isEmpty(user[`${environment.auth0PractitionerConfig.audience}practitioner_id`]);
      })
    );
    return a;
  }

  getBalance(id: string) {
    const url = this.isPractitioner
      ? `${environment.services.reward}/reward/practitioners/${id}/balance`
      : `${environment.services.reward}/reward/patients/${id}/balance`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getTransaction(id: string) {
    const url = this.isPractitioner
      ? `${environment.services.reward}/reward/practitioners/${id}/transactions`
      : `${environment.services.reward}/reward/patients/${id}/transactions`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getCampaigns() {
    const url = `${environment.services.campaign}/campaign`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getActiveCampaigns() {
    const url = `${environment.services.campaign}/campaign?status=Active&participantType=Patient`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getCampaignById(id: string) {
    const url = `${environment.services.campaign}/campaign/${id}?status=Active`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getOffers() {
    const url = `${environment.services.offer}/offer`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getOffersByCampaignId(id: string) {
    const url = `${environment.services.offer}/offer?status=Active&campaignId=${id}`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getDrawing() {
    const url = `${environment.services.drawing}/drawing`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getDrawingByCampaign(id: string) {
    const url = `${environment.services.drawing}/drawing?campaignId=${id}&status=Active`;
    return this.http.get(url).pipe(catchHttpError);
  }

  redeemOffer(endpoint: string, payload: { rewardAmount?: number; note?: string }) {
    const url = `${environment.services.offer}/${endpoint}`;
    return this.http.post(url, payload).pipe(catchHttpError);
  }

  getDrawingById(id: string) {
    const url = `${environment.services.drawing}/drawing/${id}`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getReward(id: string, drawingId: string) {
    const url = this.isPractitioner
      ? `${environment.services.drawing}/reward?practitioner=${id}&drawingId=${drawingId}&rewardType=Ticket`
      : `${environment.services.drawing}/reward?patientId=${id}&drawingId=${drawingId}&rewardType=Ticket`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getTickets(id: string) {
    const url = this.isPractitioner
      ? `${environment.services.reward}/reward?practitioner=${id}&rewardType=Ticket`
      : `${environment.services.reward}/reward?patientId=${id}&rewardType=Ticket`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getWinnigDrawingReward(drawingId: string) {
    const url = `${environment.services.drawing}/reward?drawingId=${drawingId}&status=Win&rewardType=Ticket`;
    return this.http.get(url).pipe(catchHttpError);
  }

  getTicketsForDrawing(drawingId: string, id: string) {
    const url = this.isPractitioner
      ? `${environment.services.reward}/reward?drawingId=${drawingId}&status=Active&rewardType=Ticket&practitioner=${id}`
      : `${environment.services.reward}/reward?drawingId=${drawingId}&status=Active&rewardType=Ticket&patientId=${id}`;
    return this.http.get(url).pipe(catchHttpError);
  }

  redeemPoints(drawingId: string, id: string, body: { amount: number }) {
    const url = this.isPractitioner
      ? `${environment.services.reward}/drawing/${drawingId}/practitioners/${id}/redeem`
      : `${environment.services.reward}/drawing/${drawingId}/patients/${id}/redeem`;
    return this.http.post(url, body).pipe(catchHttpError);
  }
}
