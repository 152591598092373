import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { CommunicationModule } from './communication/communication.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppStateModule } from './state/state.module';
import { getScope } from './config';
import { AuthGuard } from './auth.guard';
import { LogoutGuard } from './logout.guard';
import { CacheLocation } from '@auth0/auth0-spa-js';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { SharedModule } from './shared/shared.module';
import { FullComponent } from './layouts/full/full.component';
import { SidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { SpinnerComponent } from './shared/spinner.component';
import { MenuItems } from '../util/menu-items';
import { ReadonlyModule } from './readonly/readonly.module';
import { WinningPageComponent } from './my-care-rewards/winning-page/winning-page.component';
import { RoleGuard } from './role.guard';
import { CommunicationV1Module } from './communication-v1/communication-v1.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

const roLoginSessionItem = 'ro-login-session';
let clientId = environment.auth0Config.clientId;
let auth0Config = environment.auth0Config;
if (window.location.href.includes('/ro/')) {
  sessionStorage.setItem(roLoginSessionItem, '1');
  clientId = environment.readOnlyClientId;
} else if (window.location.href.includes('/practitioner/')) {
  sessionStorage.setItem(roLoginSessionItem, '2');
  clientId = environment.auth0PractitionerConfig.clientId;
  auth0Config = environment.auth0PractitionerConfig;
} else if (sessionStorage.getItem(roLoginSessionItem) === '1') {
  clientId = environment.readOnlyClientId;
} else if (sessionStorage.getItem(roLoginSessionItem) === '2') {
  clientId = environment.auth0PractitionerConfig.clientId;
  auth0Config = environment.auth0PractitionerConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    FullComponent,
    SidebarComponent,
    SpinnerComponent,
    WinningPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppStateModule,
    RouterModule,
    BrowserAnimationsModule,
    CommunicationModule,
    CommunicationV1Module,
    PerfectScrollbarModule,
    SharedModule,
    ReadonlyModule,
    AuthModule.forRoot(
      Object.assign(
        {
          scope: `openid email ${getScope()}`,
          cacheLocation: 'localstorage' as CacheLocation,
          skipRedirectCallback: false,
          responseType: 'code',
          authorizeTimeoutInSeconds: 600,
          httpInterceptor: {
            allowedList: Object.values(environment.services).map((s) => `${s}/*`),
          },
        },
        auth0Config,
        {
          clientId,
        }
      )
    ),
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    AuthGuard,
    RoleGuard,
    LogoutGuard,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    MenuItems,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
