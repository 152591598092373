import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private progress = new BehaviorSubject<any>(false);
  private indicator = new BehaviorSubject<any>(false);

  public progressLoader = this.progress.asObservable();
  public indicatorLoader = this.indicator.asObservable();

  show(type?: string) {
    if (type === 'progress') {
      this.progress.next(true);
    } else if (type === 'indicator') {
      this.indicator.next(true);
    } else {
      this.progress.next(true);
    }
  }

  hide(type?: string) {
    if (type === 'progress') {
      this.progress.next(false);
    } else if (type === 'indicator') {
      this.indicator.next(false);
    } else {
      this.progress.next(false);
    }
  }
}
