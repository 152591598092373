<mat-progress-bar class="progress-bar" mode="indeterminate" color="accent" *ngIf="showLoader"></mat-progress-bar>
<div>
  <div fxLayout="row wrap" class="p-l-5" fxLayoutAlign="space-between center">
    <div>
      <img [src]="practiceLogoUrl" width="60" />
    </div>
    <div>
      <button mat-icon-button (click)="logout()">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>
  <div
    fxLayout="row wrap"
    *ngIf="this.drawingDetails.id"
    fxLayout.xs="column-reverse"
    fxLayout.sm="column-reverse"
    fxLayoutAlign="space-between start"
    class="p-l-r-5"
  >
    <div fxLayout="column" fxFlex.gt-sm="45" fxFlex="100" style="width: 100%">
      <h1 fxLayout="row" class="text-black">{{ drawingDetails.title }}</h1>
      <h4 fxLayout="row" class="text-black desc">{{ drawingDetails.detailedDescription }}</h4>
      <div fxLayout="row wrap">
        <h2 class="text-black">Time left to participate</h2>
      </div>
      <div fxLayout="row wrap" class="time-container">
        <mat-card fxFlex="16" fxFlex.lt-sm="22" fxFlex.gt-sm="22" class="xsm-screen b-b-l-r-r">
          <div class="time-header">
            <span>{{ timeleft.days }}</span>
          </div>
          <div class="time-footer">
            <span>DAYS</span>
          </div>
        </mat-card>
        <mat-card fxFlex="16" fxFlex.lt-sm="22" fxFlex.gt-sm="22" class="xsm-screen b-b-l-r-r">
          <div class="time-header">
            <span>{{ timeleft.hours }}</span>
          </div>
          <div class="time-footer">
            <span>HOURS</span>
          </div>
        </mat-card>
        <mat-card fxFlex="16" fxFlex.lt-sm="22" fxFlex.gt-sm="22" class="b-b-l-r-r">
          <div class="time-header">
            <span>{{ timeleft.minutes }}</span>
          </div>
          <div class="time-footer">
            <span>MINUTES</span>
          </div>
        </mat-card>
        <mat-card fxFlex="16" fxFlex.lt-sm="22" fxFlex.gt-sm="22" class="b-b-l-r-r">
          <div class="time-header">
            <span>{{ timeleft.seconds }}</span>
          </div>
          <div class="time-footer">
            <span>SECONDS</span>
          </div>
        </mat-card>
      </div>
    </div>
    <div fxFlex.gt-sm="55" fxFlex="100">
      <img [src]="drawingDetails.heroImageURL" class="drawing-img" />
    </div>
  </div>
  <div fxLayout="row wrap" *ngIf="isSearchTickets">
    <div class="ticket-section p-l-r-5" fxFlex="100">
      <div class="ticket">
        <div class="ticket__content">
          <mat-card class="drawing-mat-card">
            <div *ngIf="!rewards.length">
              <mat-progress-bar mode="indeterminate" *ngIf="searchTicketLoader" color="accent"></mat-progress-bar>
              <mat-card-content class="waiting-container" fxLayoutAlign="center center">
                <h4 *ngIf="searchTicketLoader">Waiting for result</h4>
                <h4 *ngIf="!searchTicketLoader">
                  Sorry, you have no tickets right now. We recommend participate in our current campaign and earn
                  tickets!
                </h4>
              </mat-card-content>
            </div>
            <mat-card-content *ngIf="rewards.length">
              <div fxLayout="row wrap">
                <h4 class="ticket-title">You have earned {{ totalTickets() }} tickets</h4>
              </div>
              <div fxLayout="row wrap">
                <ng-container *ngFor="let result of rewards">
                  <div *ngFor="let item of [].constructor(result.rewardAmount); let i = index" class="ticket-container">
                    <div class="ticket-num">
                      {{ result.ticketNo }}<span *ngIf="result.rewardAmount > 1">-{{ i + 1 }}</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="m-b-20">
    <div class="section4-container" fxFlex="100">
      <div fxLayout="row wrap" fxFlex="100" class="rules-container">
        <div fxLayout="row wrap" fxLayoutAlign="center start" fxFlex="100">
          <h2 class="text-white">Rules for participation</h2>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="center start">
          <mat-card fxFlex.gt-sm="16.5" fxFlex="100" class="rule-card">
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="center start">
              <img mat-card-image src="../../assets/images/landing-page/rule1.png" class="rule-logo" />
              <p class="rule-desc" class="rule-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card fxFlex.gt-sm="16.5" fxFlex="100" class="rule-card">
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="center start">
              <img mat-card-image src="../../assets/images/landing-page/rule2.png" class="rule-logo" />
              <p class="rule-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card fxFlex.gt-sm="16.5" fxFlex="100" class="rule-card">
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="center start">
              <img mat-card-image src="../../assets/images/landing-page/rule3.png" class="rule-logo" />
              <p class="rule-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card fxFlex.gt-sm="16.5" fxFlex="100" class="rule-card">
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="center start">
              <img mat-card-image src="../../assets/images/landing-page/rule4.png" class="rule-logo" />
              <p class="rule-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card fxFlex.gt-sm="16.5" fxFlex="100" class="rule-card">
            <mat-card-content fxLayout="row wrap" fxLayoutAlign="center start">
              <img mat-card-image src="../../assets/images/landing-page/rule5.png" class="rule-logo" />
              <p class="rule-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" class="p-l-5 m-b-20">
    <div class="footer-logo" fxFlex.gt-md="30" fxFlex.gt-xs="33" fxFlex="100">
      <img src="assets/images/logo/logo.png" width="60" />
    </div>
    <div class="text-black" fxFlex.gt-md="30" fxFlex.gt-xs="28" fxFlex="100">
      <h3>Contact us</h3>
      hello@carecognitics.com <br />
      <a>+650.617.3425</a>
    </div>
    <div class="text-black" fxFlex.gt-md="40" fxFlex.gt-xs="38" fxFlex="100">
      <h3>Disclaimer</h3>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua.
    </div>
  </div>
</div>
