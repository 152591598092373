import { initialState, slotsAdapter } from './slot.model';
import { ADDED, All, CANCEL_QUERY, MODIFIED, QUERY, REMOVED, SELECT } from './slot.actions';

export function slotsReducer(state = initialState, action: All) {
  switch (action.type) {
    case ADDED:
      return slotsAdapter.addOne(action.slot, {
        ...state,
        loading: false,
      });
    case REMOVED:
      return slotsAdapter.removeOne(action.slot.id!, { ...state, loading: false });
    case MODIFIED:
      return slotsAdapter.updateOne(
        {
          id: action.slot.id!,
          changes: action.slot,
        },
        { ...state, loading: false }
      );
    case QUERY:
      if (action.scheduleId) {
        return {
          ...state,
          loading: true,
        };
      } else {
        return { ...state, loading: true };
      }
    case CANCEL_QUERY:
      return {
        ...state,
        loading: false,
      };
    case SELECT:
      return {
        ...state,
        selectedSlot: action.slot,
      };
  }
  return state;
}
