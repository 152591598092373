import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../../services/loader.service';
import { interval, Subscriber, Subscription } from 'rxjs';
import { FADE_IN } from '../../../util/animations/animation';
import { Drawing, Reward } from '@carecognitics/fhir-resources';
import { RewardService } from 'src/app/services/reward.service';
import { UserEffects } from 'src/app/state/user';
import { environment } from 'src/environments/environment';

const source = interval(1000);

@Component({
  selector: 'app-readonly-drawing',
  templateUrl: './readonly-drawing.component.html',
  styleUrls: ['./readonly-drawing.component.scss'],
  animations: [FADE_IN],
})
export class ReadonlyDrawingComponent implements OnInit, OnDestroy {
  showLoader = false;
  practiceLogoUrl = environment.tenant.logo;
  drawingDetails: Drawing = {} as Drawing;
  rewards: Reward[] = [];
  authUserSubscriber: Subscription;
  timeleft: any = {};
  isSearchTickets = false;
  searchTicketLoader = false;
  isFeatureDrawing = true;
  patientId = '';

  constructor(
    private loader: LoaderService,
    private rewardService: RewardService,
    private router: ActivatedRoute,
    public auth: UserEffects
  ) {}

  ngOnInit() {
    this.loader.progressLoader.subscribe((state) => {
      this.showLoader = state;
    });

    const id = this.router.snapshot.paramMap.get('id');
    if (id) {
      this.getDrawings(id);
    }
    this.router.queryParams.subscribe((params) => {
      if (params.pid) {
        this.patientId = params.pid;
      } else {
        this.authUserSubscriber = this.auth.userId$.subscribe((userId: string) => {
          this.patientId = userId.slice(8);
        });
      }
    });
  }

  /**
   * This function is used to get the drawing detail based on drawingId
   * @param drawingId
   */
  getDrawings(drawingId: string) {
    this.loader.show('progress');
    this.rewardService.getDrawingById(drawingId).subscribe(
      (response: Drawing) => {
        this.drawingDetails = response;
        if (this.drawingDetails) {
          this.setTimer(this.drawingDetails.runDate);
          if (this.patientId) {
            this.searchRaffleTickets();
          }
        }
        this.loader.hide('progress');
      },
      () => {
        this.loader.hide('progress');
      }
    );
  }

  searchRaffleTickets() {
    this.searchTicketLoader = true;
    this.rewards = [];
    if (this.drawingDetails && this.drawingDetails.id) {
      this.isSearchTickets = true;
      this.rewardService.getReward(this.patientId, this.drawingDetails.id).subscribe(
        (response: any) => {
          this.rewards = response;
          this.searchTicketLoader = false;
        },
        () => {
          this.searchTicketLoader = false;
        }
      );
    }
  }

  /**
   * This function is used to show the timer on UI
   * @param date
   */
  setTimer(date: string) {
    const countDownDate = new Date(date).getTime();

    const timer = source.subscribe(() => {
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      const days = this.prependZero(Math.floor(distance / (1000 * 60 * 60 * 24)));
      const hours = this.prependZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      const minutes = this.prependZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      const seconds = this.prependZero(Math.floor((distance % (1000 * 60)) / 1000));

      this.timeleft = { days, hours, minutes, seconds };

      // If the count down is over, write some text
      if (distance < 0) {
        timer.unsubscribe();
      }
    });
  }

  /**
   * This function is to add the 0 to the timer if its a single digit (e.g. 9:00 -> 09:00)
   * @param time
   */
  prependZero(time: number) {
    if (time <= 0 || isNaN(time)) {
      return '00';
    } else if (time > 0 && time <= 9) {
      return '0' + time;
    }
    return time;
  }

  totalTickets() {
    let totalTickets = 0;
    this.rewards &&
      this.rewards.map((x) => {
        totalTickets += x.rewardAmount;
      });
    return totalTickets;
  }

  ngOnDestroy() {
    if (this.authUserSubscriber) {
      this.authUserSubscriber.unsubscribe();
    }
  }

  logout(): void {
    this.auth.logout();
  }
}
