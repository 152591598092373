import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Communication } from './communication.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SendCommunicationRequest } from './communication.model';
import pickBy from 'lodash-es/pickBy';
import { take } from 'rxjs/operators';
import { catchHttpError } from '../../../util/http';

@Injectable()
export class CommunicationService {
  constructor(private httpClient: HttpClient) {}

  public sendCommunication(communication: SendCommunicationRequest): Observable<Communication> {
    const formData = new FormData();
    for (const [key, value] of Object.entries(pickBy(communication, (value, key) => key !== 'tempId'))) {
      if (Array.isArray(value)) {
        value.forEach((att) => {
          formData.append(key, att.rawFile, att.name);
        });
      } else if (value !== undefined) {
        formData.append(key, value.toString());
      }
    }
    return this.httpClient
    .post<Communication>(`${environment.services.communicationService}/message/form`, formData)
    .pipe(take(1));
  }

  updateStatus(ids: string[]) {
    return this.httpClient
      .post<any>(`${environment.services.communicationService}/message/status/complete`, { communicationId: ids })
      .pipe(catchHttpError);
  }
}
