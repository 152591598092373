import { Slot } from '@carecognitics/fhir-resources';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../state';

export const slotsFeatureKey = 'slots';

export interface State extends EntityState<Slot> {
  loading: boolean;
  error?: any;
  selectedSlot?: Slot | null;
}

export const slotsAdapter = createEntityAdapter<Slot>({
  sortComparer: (a, b) => b?.start?.localeCompare(a.start) || 0, // sort descending by start
});

export const initialState: State = slotsAdapter.getInitialState({ loading: false });

export const getSlotsState = createFeatureSelector<AppState, State>(slotsFeatureKey);
export const getSelectedSlot = createSelector(getSlotsState, (state) => state.selectedSlot);
// export const isLoadingSlots = createSelector(getSlotsState, (state) => state.loading);

export const { selectIds, selectAll: selectAllSlots, selectEntities, selectTotal } = slotsAdapter.getSelectors(
  getSlotsState
);

export { Slot };
