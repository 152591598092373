<div
  class="container"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
  [scrollWindow]="false"
  *ngIf="!loader"
  [ngClass.lt-sm]="{ 'p-l-20': true, 'p-r-20': true }"
>
  <div fxLayout="row">
    <div fxFlex class="chat-middle-box">
      <div *ngFor="let communication of communications; trackBy: trackByMethod">
        <div class="chat-list even" *ngIf="communication.extractedData.type === 'even'">
          <div class="chat-message">
            <span class="chat-date" *ngIf="communication.sent">{{
              DateTime.fromISO(communication.sent).toRelative()
            }}</span>
            <div fxLayout="row" fxLayoutAlign="end">
              <div [matTooltip]="communication.extractedData.name" fxFlex="7" fxFlex.lt-sm="15">
                <ngx-avatar
                  size="40"
                  [name]="communication.extractedData.name"
                  bgColor="#f2f4f8"
                  fgColor="#000"
                  initialsSize="2"
                >
                </ngx-avatar>
              </div>
              <div class="chat-block" [ngClass]="{ template: communication.template }">
                <mat-accordion *ngIf="communication.template">
                  <mat-expansion-panel @fadeIn class="msg">
                    <mat-expansion-panel-header>
                      <mat-panel-title [ngClass.lt-sm]="{ ellipsis: true }">
                        <span>
                          <img class="m-0 m-r-10" width="20" [src]="communication.extractedData.icon" />
                        </span>
                        {{ communication.template.name }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <app-template-viewer
                        @fadeIn
                        [HTMLTemplate]="communication.extractedData.msg.contentString"
                        *ngIf="
                          communication.extractedData &&
                          communication.extractedData.msg &&
                          communication.extractedData.msg.contentString
                        "
                      >
                      </app-template-viewer>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
                <div fxLayout="row wrap" *ngIf="!communication.template">
                  <span>
                    <img class="m-0 m-r-10" width="20" [src]="communication.extractedData.icon" />
                  </span>
                  <span fxFlex class="msg m-0" *ngIf="communication.payload">
                    <span>
                      {{
                        communication.extractedData.isReadMore
                          ? communication.extractedData.msg &&
                            communication.extractedData.msg.contentString &&
                            communication.extractedData.msg.contentString.substr(0, max)
                          : communication.extractedData.msg.contentString &&
                            communication.extractedData.msg.contentString
                      }}</span
                    >
                    <span
                      *ngIf="
                        communication.extractedData.msg &&
                        communication.extractedData.msg.contentString &&
                        communication.extractedData.msg.contentString.length > max
                      "
                      class="pointer"
                      (click)="onReadMore(communication.extractedData)"
                    >
                      <span *ngIf="communication.extractedData.isReadMore">...</span>
                      <span class="read-more link">{{
                        communication.extractedData.isReadMore ? ' Read More' : ' Read Less'
                      }}</span>
                    </span>
                  </span>
                </div>
                <div
                  fxLayout="row wrap"
                  [ngClass]="{ 'p-b-10': communication.extractedData.dynamicTemplate }"
                  *ngIf="communication.extractedData.attachments && communication.extractedData.attachments.length"
                >
                  <div
                    class="attachment-container pointer"
                    *ngFor="let attachment of communication.extractedData.attachments"
                    (click)="chatService.downloadFile(communication.id!, attachment)"
                  >
                    <span *ngIf="attachment.fileDetail?.type === 'image'" fxLayout="center">
                      <img
                        width="30"
                        *ngIf="!attachment.loading && attachment.blob"
                        [src]="chatService.safeURL(attachment.blob)"
                      />
                      <ngx-skeleton-loader
                        *ngIf="attachment.loading"
                        count="1"
                        [theme]="{ height: '40px', width: '30px', marginBottom: '0', marginRight: '10px' }"
                      >
                      </ngx-skeleton-loader>
                    </span>
                    <img
                      width="30"
                      *ngIf="attachment.fileDetail?.type !== 'image'"
                      [src]="attachment.fileDetail?.src"
                    />

                    <audio
                      controls
                      *ngIf="attachment.fileDetail && attachment.fileDetail.type === 'audio'"
                      [src]="chatService.safeURL(attachment.blob)"
                    ></audio>
                    <span>{{ attachment.contentAttachment!.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-list" *ngIf="communication.extractedData.type === 'odd'">
          <div class="chat-message">
            <div fxLayout="row" fxLayoutAlign="start">
              <div fxFlex="7" fxFlex.lt-sm="15"></div>
              <span
                class="chat-date odd"
                *ngIf="communication.sent"
                [ngClass]="{ red: communication.status === 'aborted' || communication.status === 'suspended' }"
                >{{ DateTime.fromISO(communication.sent).toRelative() }}</span
              >
              <span
                class="chat-date"
                *ngIf="communication.status"
                [ngClass]="{ red: communication.status === 'aborted' || communication.status === 'suspended' }"
                >,
                <span *ngIf="communication.status === 'preparation'"> Sending </span>
                <span *ngIf="communication.status === 'completed'"> Read </span>
                <span *ngIf="communication.status === 'in-progress'"> Sent </span>
                <span *ngIf="communication.status === 'aborted' || communication.status === 'suspended'" class="red">
                  Failed
                </span>
              </span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start">
              <div [matTooltip]="(user | async)!.name" fxFlex="7" fxFlex.lt-sm="15">
                <ngx-avatar size="40" [name]="(user | async)!.name" bgColor="#e3f3fd" fgColor="#000" initialsSize="2">
                </ngx-avatar>
              </div>
              <div class="chat-block bg-light-info" [ngClass]="{ template: communication.template }">
                <mat-accordion *ngIf="communication.template">
                  <mat-expansion-panel class="bg-light-info" @fadeIn>
                    <mat-expansion-panel-header>
                      <mat-panel-title [ngClass.lt-sm]="{ ellipsis: true }">
                        <span>
                          <img class="m-0 m-r-10" width="20" [src]="communication.extractedData.icon" />
                        </span>
                        {{ communication.template.name }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <app-template-viewer
                        [HTMLTemplate]="communication.extractedData.msg.contentString"
                        *ngIf="
                          communication.extractedData &&
                          communication.extractedData.msg &&
                          communication.extractedData.msg.contentString
                        "
                      >
                      </app-template-viewer>
                    </ng-template>
                  </mat-expansion-panel>
                </mat-accordion>
                <div fxLayout="row wrap" *ngIf="!communication.template">
                  <span>
                    <img class="m-0 m-r-10" width="20" [src]="communication.extractedData.icon" />
                  </span>
                  <span fxFlex class="msg m-0" *ngIf="communication.payload">
                    <span>
                      {{
                        communication.extractedData.isReadMore
                          ? communication.extractedData.msg &&
                            communication.extractedData.msg.contentString &&
                            communication.extractedData.msg.contentString.substr(0, max)
                          : communication.extractedData.msg.contentString &&
                            communication.extractedData.msg.contentString
                      }}</span
                    >
                    <span
                      *ngIf="
                        communication.extractedData.msg &&
                        communication.extractedData.msg.contentString &&
                        communication.extractedData.msg.contentString.length > max
                      "
                      class="pointer"
                      (click)="onReadMore(communication.extractedData)"
                    >
                      <span *ngIf="communication.extractedData.isReadMore">...</span>
                      <span class="read-more link">{{
                        communication.extractedData.isReadMore ? ' Read More' : ' Read Less'
                      }}</span>
                    </span>
                  </span>
                </div>
                <div
                  fxLayout="row wrap"
                  [ngClass]="{ 'p-b-10': communication.extractedData.dynamicTemplate }"
                  *ngIf="communication.extractedData.attachments && communication.extractedData.attachments.length"
                >
                  <div
                    class="attachment-container pointer"
                    *ngFor="let attachment of communication.extractedData.attachments"
                    (click)="chatService.downloadFile(communication.id!, attachment)"
                  >
                    <span *ngIf="attachment && attachment.fileDetail!.type === 'image'" fxLayout="center">
                      <img
                        width="30"
                        *ngIf="!attachment.loading && attachment.blob"
                        [src]="chatService.safeURL(attachment.blob)"
                      />
                      <ngx-skeleton-loader
                        *ngIf="attachment.loading"
                        count="1"
                        [theme]="{ height: '40px', width: '30px', marginBottom: '0', marginRight: '10px' }"
                      >
                      </ngx-skeleton-loader>
                    </span>
                    <img
                      width="30"
                      *ngIf="attachment && attachment.fileDetail!.type !== 'image'"
                      [src]="attachment.fileDetail!.src"
                    />
                    <audio
                      controls
                      *ngIf="attachment.fileDetail && attachment.fileDetail.type === 'audio'"
                      [src]="chatService.safeURL(attachment.blob)"
                    ></audio>
                    <span>{{ attachment.contentAttachment!.title }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mailbox message-widget" *ngIf="loader" [ngClass.lt-sm]="{ 'p-l-20': true }">
  <div class="message-center">
    <div *ngFor="let message of [].constructor(6); let i = index">
      <a class="chat-item fake-item" fxLayoutAlign="start start" *ngIf="i % 4 < 2">
        <div class="m-r-15">
          <ngx-skeleton-loader
            count="1"
            appearance="circle"
            [theme]="{
              height: '40px',
              width: '40px',
              margin: '0px',
              'border-radius': '10px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
        <div class="mail-content" fxFlex>
          <div fxLayout="row">
            <div fxFlex.lt-sm="100" fxFlex="50">
              <ngx-skeleton-loader count="1" [theme]="{ height: '30px', margin: '-3px' }"> </ngx-skeleton-loader>
            </div>
          </div>
          <div fxLayout="row wrap" class="m-t-5">
            <div fxFlex.lt-sm="40" fxFlex="15">
              <ngx-skeleton-loader count="1" [theme]="{ height: '25px', width: '70%', margin: '-3px' }">
              </ngx-skeleton-loader>
            </div>
            <div fxFlex.lt-sm="40" fxFlex="15">
              <ngx-skeleton-loader count="1" [theme]="{ height: '25px', width: '70%', margin: '-3px' }">
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </a>

      <a class="chat-item fake-item" fxLayoutAlign="end start" *ngIf="i % 4 > 1">
        <div class="m-r-15">
          <ngx-skeleton-loader
            count="1"
            appearance="circle"
            [theme]="{
              height: '40px',
              width: '40px',
              margin: '0px',
              'border-radius': '10px'
            }"
          >
          </ngx-skeleton-loader>
        </div>
        <div class="mail-content" fxFlex.lt-sm="100" fxFlex="50">
          <div fxLayout="row">
            <div fxFlex>
              <ngx-skeleton-loader count="1" [theme]="{ height: '30px', margin: '-3px' }"> </ngx-skeleton-loader>
            </div>
          </div>
          <div fxLayout="row wrap" class="m-t-5">
            <div fxFlex.lt-sm="40" fxFlex="30">
              <ngx-skeleton-loader count="1" [theme]="{ height: '25px', width: '70%', margin: '-3px' }">
              </ngx-skeleton-loader>
            </div>
            <div fxFlex.lt-sm="40" fxFlex="30">
              <ngx-skeleton-loader count="1" [theme]="{ height: '25px', width: '70%', margin: '-3px' }">
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
