import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as scheduleActions from './schedule.actions';
import { Schedule } from './schedule.model';
import { AppState } from '../state';
import firebase from 'firebase/app';
import 'firebase/firestore';

type Action = scheduleActions.All;

@Injectable()
export class ScheduleEffects {
  private cancelQueryState$ = new Subject<void>();
  private cancelQueryObservable$ = this.cancelQueryState$.asObservable();
  private queryState$ = new Subject<boolean>();
  private queryObservable$ = this.queryState$.asObservable();
  query$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<scheduleActions.Query>(scheduleActions.QUERY),
      tap(() => this.cancelQueryState$.next()), // cancel any queries in progress
      switchMap((query) => {
        return this.db
          .collection<Schedule>('Schedule', (f: firebase.firestore.Query) => {
            if (query.scheduleIds && query.scheduleIds.length) {
              f = f.where(firebase.firestore.FieldPath.documentId(), 'in', query.scheduleIds);
            }
            return f; //.orderBy('planningHorizon.start', 'desc');
          })
          .stateChanges()
          .pipe(
            tap(() => this.queryState$.next(true)),
            takeUntil(this.cancelQueryObservable$.pipe(tap(() => this.queryState$.next(false))))
          );
      }),
      mergeMap((d) => d),
      map((action) => {
        return {
          type: `[Schedule] ${action.type}`,
          schedule: {
            ...action.payload.doc.data(),
          },
        } as Action;
      }),
      catchError((err) => of(new scheduleActions.ScheduleError(err)))
    )
  );
  cancelQuery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scheduleActions.CANCEL_QUERY),
        // tap(() => this.queryComponent$.next()), // do we need to trigger one value?
        switchMap(() => this.queryObservable$.pipe(debounceTime(environment.firestore.unsubscribeDelay))),
        take(1),
        tap(() => this.cancelQueryState$.next())
      ),
    {
      dispatch: false,
    }
  );

  public cancelQuery() {
    this.store.dispatch(new scheduleActions.CancelQuery());
  }

  public query(scheduleIds?: string[]) {
    this.store.dispatch(new scheduleActions.Query(scheduleIds));
  }

  public select(schedule: Schedule | null | string) {
    this.store.dispatch(new scheduleActions.Select(schedule));
  }

  constructor(private actions$: Actions, private db: AngularFirestore, private store: Store<AppState>) {}
}
