import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

import { CommunicationEffect, communicationReducer } from './communications';
import { environment } from '../../environments/environment';
import { CommunicationService } from './communications/communication.service';
import { UserEffects, userReducer } from './user';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from '../app.effects';
import { ScheduleEffects, schedulesReducer } from './schedules';
import { SlotEffects, slotsReducer } from './slots';
import { AppointmentEffects, appointmentsReducer } from './appointment';
import { TemplateEffect, templateReducer } from './templates';
import { PractitionerEffects, practitionerReducer } from './practitioner';
import { RewardEffects, rewardssReducer } from './reward';

export const firebaseConfig = environment.firebaseConfig;

@NgModule({
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    StoreModule.forRoot({
      router: routerReducer,
      user: userReducer as any,
      schedules: schedulesReducer as any,
      slots: slotsReducer as any,
      appointment: appointmentsReducer as any,
      template: templateReducer as any,
      communication: communicationReducer as any,
      practitioner: practitionerReducer as any,
      reward: rewardssReducer as any,
    }),
    EffectsModule.forRoot([
      AppEffects,
      UserEffects,
      ScheduleEffects,
      SlotEffects,
      AppointmentEffects,
      RewardEffects,
      TemplateEffect,
      TemplateEffect,
      CommunicationEffect,
      PractitionerEffects,
    ]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    CommunicationEffect,
    UserEffects,
    ScheduleEffects,
    AppointmentEffects,
    RewardEffects,
    CommunicationService,
    ScreenTrackingService,
    UserTrackingService,
    TemplateEffect,
    PractitionerEffects,
  ],
})
export class AppStateModule {}
