import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounce,
  debounceTime,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { Practitioner, State } from './practitioner.model';

import * as practitionerActions from './practitioner.actions';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import 'firebase/firestore';
import { TemplateEffect } from '../templates';
import { AppState } from '../state';

type Action = practitionerActions.All;

export const PractitionerQuery = {
  isLoading: (state: AppState) => state.practitioner.loading,
  error: (state: AppState) => state.practitioner.error
};

@Injectable()
export class PractitionerEffects {
  // error$ = this.store.select(PractitionerQuery.error);
  // isLoading$ = this.store.select(PractitionerQuery.isLoading);

  private cancelQueryState$ = new Subject<void>();
  private cancelQueryObservable$ = this.cancelQueryState$.asObservable();
  private queryState$ = new Subject<boolean>();
  private queryObservable$ = this.cancelQueryState$.asObservable();
  query$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<practitionerActions.Query>(practitionerActions.QUERY),
      tap(() => this.cancelQueryState$.next()),
      switchMap((query) => {
        return this.db
          .collection<Practitioner>('Practitioner')
          .stateChanges()
          .pipe(
            tap(() => this.queryState$.next(true)),
            takeUntil(this.cancelQueryObservable$.pipe(tap(() => this.queryState$.next(false))))
          );
      }),
      mergeMap((d) => d),
      map((action) => {
        return {
          type: `[Practitioner] ${action.type}`,
          payload: {
            ...action.payload.doc.data(),
          },
        } as Action;
      }),
      catchError((err) => of(new practitionerActions.PractitionerError(err)))
    )
  );
  cancelQuery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(practitionerActions.CANCEL_QUERY),
        // tap(() => this.queryComponent$.next()), // do we need to trigger one value?
        switchMap(() => this.queryObservable$.pipe(debounceTime(environment.firestore.unsubscribeDelay))),
        take(1),
        tap(() => this.cancelQueryState$.next())
      ),
    {
      dispatch: false,
    }
  );

  public cancelQuery() {
    this.store.dispatch(new practitionerActions.CancelQuery());
  }

  public select(practitioner: Practitioner | null | string) {
    this.store.dispatch(new practitionerActions.Select(practitioner));
  }

  public query() {
    this.store.dispatch(new practitionerActions.Query());
  }

  // ************************************************
  // Internal Code
  // ************************************************

  constructor(
    private actions$: Actions,
    private db: AngularFirestore,
    private store: Store<State>,
    private auth: AuthService,
    private templateEffect: TemplateEffect
  ) {}
}
