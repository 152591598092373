<div fxLayout="row" fxLayoutAlign="space-between start" class="m-b-20">
  <div class="title">All Tickets</div>
  <div (click)="onClose()" class="close-btn" fxLayoutAlign="center center">
    <mat-icon>clear</mat-icon>
  </div>
</div>
<div>
  <div fxLayout="row wrap">
    <div class="ticket-title">
      <span>Total Tickets ({{ popup.totalTickets }})</span>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div fxLayout="row wrap" class="ticket" fxLayoutAlign="space-between">
      <span *ngFor="let reward of popup.rewards" class="ticket-bg">
        <span>{{ reward.ticketNo }}</span>
      </span>
    </div>
  </div>
</div>
