<div fxLayout="row wrap" fxLayoutAlign="center">
  <div class="drawing">
    <button mat-icon-button (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
    <div class="section1 m-b-30" ngClass.gt-sm="m-30">
      <div
        *ngIf="drawingSkeleton"
        fxLayout="row wrap"
        fxLayout.lt-sm="column-reverse"
        fxLayoutAlign.gt-sm="space-between"
      >
        <div fxLayout="column" fxFlex.gt-sm="49" fxFlex="100" ngStyle.gt-sm="margin-top: 5%;">
          <div fxLayout="row" fxFlex.lt-sm="15" fxFlex.gt-sm="25" fxLayoutAlign="center">
            <ngx-skeleton-loader count="1" [theme]="{ height: '150px', width: '150px' }"></ngx-skeleton-loader>
          </div>
          <div class="title">
            <ngx-skeleton-loader count="1" [theme]="{ height: '40px' }"></ngx-skeleton-loader>
          </div>
          <div class="description">
            <ngx-skeleton-loader count="1" [theme]="{ height: '300px' }"></ngx-skeleton-loader>
          </div>
        </div>
        <div fxFlex.gt-sm="49" fxFlex="100" fxHide fxShow.gt-sm>
          <ngx-skeleton-loader count="1" [theme]="{ height: '600px' }"></ngx-skeleton-loader>
        </div>
      </div>
      <div
        *ngIf="!drawingSkeleton"
        fxLayout="row wrap"
        fxLayout.lt-sm="column-reverse"
        fxLayoutAlign.gt-sm="space-between"
      >
        <div fxLayout="column" fxFlex.gt-sm="49" fxFlex="100" ngStyle.gt-sm="margin-top: 5%;">
          <div fxFlex.lt-sm="15" fxFlex.gt-sm="25" fxLayoutAlign="center">
            <img src="{{ drawingDetails.thumbnailImageURL }}" width="200" />
          </div>
          <div class="title">{{ drawingDetails.title }}</div>
          <div class="description">{{ drawingDetails.detailedDescription }}</div>
        </div>
        <div fxLayout="column" fxFlex.gt-sm="49" fxFlex="100" fxHide fxShow.gt-sm>
          <img src="{{ drawingDetails.heroImageURL }}" width="550" />
        </div>
      </div>
    </div>
    <div class="section2" ngClass.gt-sm="m-30">
      <div fxLayout="row wrap" fxLayoutAlign.gt-sm="space-between">
        <div fxFlex.gt-sm="49" fxFlex="100" class="m-b-30" *ngIf="ticketSkeleton">
          <div class="title">
            <ngx-skeleton-loader count="1" [theme]="{ height: '30px', margin: '0' }"> </ngx-skeleton-loader>
          </div>
          <div class="ticket">
            <div class="title">
              <ngx-skeleton-loader count="1" [theme]="{ height: '30px', margin: '0' }"> </ngx-skeleton-loader>
            </div>
            <div class="number">
              <span fxHide fxShow.lt-sm>
                <ngx-skeleton-loader count="1" [theme]="{ height: '115px' }"></ngx-skeleton-loader>
              </span>
              <span fxHide fxShow.gt-sm>
                <ngx-skeleton-loader count="1" [theme]="{ height: '115px' }"></ngx-skeleton-loader>
              </span>
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="49" fxFlex="100" class="m-b-30" *ngIf="!ticketSkeleton && userId">
          <div class="title">TICKETS EARNED -></div>
          <div class="ticket">
            <div class="title">
              <span>Tickets ({{ totalTickets }})</span>
            </div>
            <div class="number" *ngIf="rewards.length > 0">
              <span fxHide fxShow.lt-sm>
                <span *ngFor="let reward of rewards | slice: 0:9; let i = index">
                  <span>
                    &nbsp; {{ reward.ticketNo }}<span *ngIf="i < totalTickets">,</span>
                    <span *ngIf="(i + 1) % 2 == 0"><br /></span>
                  </span>
                </span>
                <span *ngIf="totalTickets > 9">
                  & <span class="more-tickets" (click)="showMoreTickets()">more</span></span
                >
              </span>
              <span fxHide fxShow.gt-xs>
                <span *ngFor="let reward of rewards | slice: 0:17; let i = index">
                  <span>
                    &nbsp; {{ reward.ticketNo }}<span *ngIf="i < totalTickets - 1">,</span>
                    <span *ngIf="(i + 1) % 3 == 0"><br /></span>
                  </span>
                </span>
                <span *ngIf="totalTickets > 17">
                  & <span class="more-tickets" (click)="showMoreTickets()">more</span></span
                >
              </span>
            </div>
          </div>
        </div>
        <div fxFlex.gt-sm="49" fxFlex="100" class="m-b-30">
          <div *ngIf="drawingSkeleton">
            <div class="title">
              <ngx-skeleton-loader count="1" [theme]="{ height: '30px', margin: '0' }"></ngx-skeleton-loader>
            </div>
            <div class="time">
              <div class="title">
                <ngx-skeleton-loader count="1" [theme]="{ height: '30px', margin: '0' }"> </ngx-skeleton-loader>
              </div>
              <div class="number">
                <span fxHide fxShow.lt-sm>
                  <ngx-skeleton-loader count="1" [theme]="{ height: '115px' }"></ngx-skeleton-loader>
                </span>
                <span fxHide fxShow.gt-sm>
                  <ngx-skeleton-loader count="1" [theme]="{ height: '120px' }"></ngx-skeleton-loader>
                </span>
              </div>
            </div>
          </div>
          <div *ngIf="!drawingSkeleton">
            <div class="title">TIME LEFT FOR THE DRAWING:</div>

            <div class="time">
              <div class="drawing-date m-b-10" fxLayoutAlign="center">
                <span>{{ drawingDetails.runDate | date: 'MMMM dd' }}</span>
              </div>
              <div fxLayoutAlign="center">
                <div class="time-box">
                  <div class="white-box"></div>
                  <div class="num">{{ timeleft.days }}</div>
                  <span>Days</span>
                </div>
                <div class="time-box">
                  <div class="white-box"></div>
                  <div class="num">{{ timeleft.hours }}</div>
                  <span>Hours</span>
                </div>
                <div class="time-box">
                  <div class="white-box"></div>
                  <div class="num">{{ timeleft.minutes }}</div>
                  <span>Minutes</span>
                </div>
                <div class="time-box">
                  <div class="white-box"></div>
                  <div class="num">{{ timeleft.seconds }}</div>
                  <span>Seconds</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section3 m-b-30" ngClass.gt-sm="m-30">
      <div class="title">GIVEAWAY PRIZES</div>
      <div fxLayout="row wrap" fxLayoutAlign.lt-sm="center center" *ngIf="drawingSkeleton">
        <div class="card" *ngFor="let item of [].constructor(4)">
          <ngx-skeleton-loader count="1" [theme]="{ height: '200px', width: '200px', marginLeft: '20px' }">
          </ngx-skeleton-loader>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="center" *ngIf="drawingAwardRulePrize.length > 0 && !drawingSkeleton">
        <div class="card" fxLayoutAlign="stretch" *ngFor="let item of drawingAwardRulePrize; let i = index">
          <div class="num">
            <span>{{ i + 1 }}</span>
          </div>
          <mat-card>
            <mat-card-content>
              <div class="p-t-20 p-b-20">
                <div class="prize"><span *ngIf="item.reward === 'Cash'">$</span>{{ item.rewardValue }}</div>
                <div class="prize-desc">{{ item.title }}</div>
              </div>
            </mat-card-content>
          </mat-card>
          <div class="num-of-winners">
            <span>{{ item.numberOfTicketsGranted }} Winners</span>
          </div>
        </div>
      </div>
    </div>
    <mat-card class="section4 m-b-30" ngClass.gt-sm="m-30">
      <mat-card-content fxFlex="100">
        <div fxLayout="row wrap" class="m-b-10">
          <div class="research-button">Research Shows</div>
        </div>
        <div fxLayout="row wrap" class="title">
          Patients do better when they are proactive in managing their own health
        </div>
        <div fxLayout="row wrap" class="sub-title m-b-20">
          As your care ally, we support your journey to better health by providing the following benefits:
        </div>
        <div fxLayout="row wrap" fxLayoutAlign.gt-sm="space-between center">
          <div fxFlex.gt-sm="30" fxFlex="100" class="card">
            <div fxLayout="row" class="card-title">
              <img src="assets/images/care-rewards/leader-board/face.png" width="50" />
              <div class="m-l-5">Appointment Scheduling</div>
            </div>
            <div class="card-sub-title">Our care team can help with your follow up appointments with ease.</div>
          </div>
          <div fxFlex.gt-sm="30" fxFlex="100" class="card">
            <div fxLayout="row" class="card-title">
              <img src="assets/images/care-rewards/leader-board/chat.png" width="50" />
              <div class="m-l-5">Continuous Care</div>
            </div>
            <div class="card-sub-title">Our Care Team will periodically check to see how you are doing.</div>
          </div>
          <div fxFlex.gt-sm="30" fxFlex="100" class="card">
            <div fxLayout="row" class="card-title">
              <img src="assets/images/care-rewards/leader-board/heart.png" width="50" />
              <div class="m-l-5">Dedicated VIP Care Team</div>
            </div>
            <div class="card-sub-title">Access to dedicated triage line for the care and support you need.</div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div fxLayout="row wrap" class="footer">
      <div fxFlex.gt-sm="50" fxFlex="100" fxLayoutAlign.gt-sm="start center">
        <a href="https://carecognitics.com/" target="_blank">
          <img src="assets/images/care-rewards/leader-board/footer-logo.png" width="200" />
        </a>
      </div>
      <div fxFlex.gt-sm="50" fxFlex="100" fxLayoutAlign.gt-sm="end center">
        <div>
          <a href="https://carecognitics.com/about/" target="_blank"><span>Rules for Participation</span></a>
          <br />
          <a href="https://carecognitics.com/news/" target="_blank"><span>Terms & Conditions</span></a> <br />
          <a href="https://carecognitics.com/privacy/" target="_blank"><span>Privacy Policy</span></a> <br />
        </div>
      </div>
    </div>
  </div>
</div>
