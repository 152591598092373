<div class="user-profile">
  <div class="pointer close-button" (click)="onClickMenuOption()">
    <img src="assets/images/icons/close.svg">
  </div>
  <img [src]="logo" width="75" class="logo" />
  <div class="user-name">{{userDetails.name}}</div>
  <!-- <div class="provider">“Brand Provider”</div> -->
</div>

<mat-nav-list appAccordion>
  <div *ngFor="let menuitem of sideMenuItems">
    <mat-list-item [ngClass]="{ disabled: sideBarSettings[menuitem.key].disable }" appAccordionLink
      *ngIf="!sideBarSettings[menuitem.key].hide" routerLinkActive="selected" group="{{ menuitem.state }}"
      (selectedOption)="selectedOption($event)">
      <a appAccordionToggle [routerLink]="['/app/', menuitem.state]" *ngIf="menuitem.type === 'link'"
        (click)="onClickMenuOption()">
        <mat-icon class="m-r-5" *ngIf="!menuitem.imageUrl">{{ menuitem.icon }}</mat-icon>
        <span class="font-22" style="font-weight: 100" *ngIf="!menuitem.imageUrl">{{ menuitem.name }}</span>
        <img *ngIf="menuitem.imageUrl && selectedOptionRoute.route !== menuitem.state" [src]="menuitem.imageUrl"
          style="width: 95%" />
        <img *ngIf="menuitem.imageUrl && selectedOptionRoute.route === menuitem.state && !selectedOptionRoute.selected"
          [src]="menuitem.imageUrl" style="width: 95%" />
        <img *ngIf="
            menuitem.transparentImageUrl && selectedOptionRoute.route === menuitem.state && selectedOptionRoute.selected
          " [src]="menuitem.transparentImageUrl" style="width: 95%" />
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        <mat-icon class="font-20" *ngIf="sideBarSettings[menuitem.key].lock">lock</mat-icon>
      </a>

      <a appAccordionToggle href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'"
        (click)="onClickMenuOption()">
        <mat-icon>{{ menuitem.icon }}</mat-icon>
        <span>{{ menuitem.name }}</span>
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        <mat-icon class="font-20" *ngIf="sideBarSettings[menuitem.key].lock">lock</mat-icon>
      </a>
      <a appAccordionToggle href="{{ menuitem.state }}" target="_blank" *ngIf="menuitem.type === 'extTabLink'"
        (click)="onClickMenuOption()">
        <mat-icon *ngIf="!menuitem.imageUrl">{{ menuitem.icon }}</mat-icon>
        <span *ngIf="!menuitem.imageUrl">{{ menuitem.name }}</span>
        <img *ngIf="menuitem.imageUrl && selectedOptionRoute.route !== menuitem.state" [src]="menuitem.imageUrl"
          style="width: 95%" />
        <img *ngIf="menuitem.imageUrl && selectedOptionRoute.route === menuitem.state && !selectedOptionRoute.selected"
          [src]="menuitem.imageUrl" style="width: 95%" />
        <img *ngIf="
            menuitem.transparentImageUrl && selectedOptionRoute.route === menuitem.state && selectedOptionRoute.selected
          " [src]="menuitem.transparentImageUrl" style="width: 95%" />
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        <mat-icon class="font-20" *ngIf="sideBarSettings[menuitem.key].lock">lock</mat-icon>
      </a>

      <a appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'" (click)="onClickMenuOption()">
        <mat-icon *ngIf="!menuitem.imageUrl">{{ menuitem.icon }}</mat-icon>
        <span *ngIf="!menuitem.imageUrl">{{ menuitem.name }}</span>
        <img *ngIf="menuitem.imageUrl && selectedOptionRoute.route !== menuitem.state" [src]="menuitem.imageUrl"
          style="width: 95%" />
        <img *ngIf="menuitem.imageUrl && selectedOptionRoute.route === menuitem.state && !selectedOptionRoute.selected"
          [src]="menuitem.imageUrl" style="width: 95%" />
        <img *ngIf="
            menuitem.transparentImageUrl && selectedOptionRoute.route === menuitem.state && selectedOptionRoute.selected
          " [src]="menuitem.transparentImageUrl" style="width: 95%" />
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        <mat-icon class="font-20" *ngIf="sideBarSettings[menuitem.key].lock">lock</mat-icon>
      </a>
      <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
        <div *ngFor="let childitem of menuitem.children"
          [ngClass]="{ disabled: sideBarSettings[menuitem.key].children[childitem.key].disable }">
          <mat-list-item routerLinkActive="selected"
            *ngIf="!sideBarSettings[menuitem.key].children[childitem.key].hide">
            <a [routerLink]="['/app/', menuitem.state, childitem.state]" *ngIf="childitem.type === 'link'"
              class="relative" routerLinkActive="selected" (click)="onClickMenuOption()">{{ childitem.name }}
              <mat-icon class="font-20 m-l-5"
                *ngIf="sideBarSettings[menuitem.key].children[childitem.key].lock">lock</mat-icon>
            </a>
          </mat-list-item>
        </div>
        <div *ngFor="let childitem of menuitem.children">
          <mat-list-item [ngClass]="{ disabled: sideBarSettings[menuitem.key].children[childitem.key].disable }"
            *ngIf="!sideBarSettings[menuitem.key].children[childitem.key].hide">
            <a class="" href="javascript: void(0);" *ngIf="childitem.type === 'subchild'" (click)="clickEvent()"
              [ngClass]="status ? 'selected' : ''">
              <span>{{ childitem.name }}</span>
              <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
              <span fxFlex></span>
            </a>
            <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
              <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
                <a [routerLink]="['/app/', menuitem.state, childitem.state, child.state]" routerLinkActive="selected"
                  (click)="onClickMenuOption()">{{ child.name }}</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-list-item>
        </div>
      </mat-nav-list>

      <div class="saperator text-muted" *ngIf="menuitem.type === 'saperator'">
        <span>{{ menuitem.name }}</span>
      </div>
    </mat-list-item>
  </div>
</mat-nav-list>