import { Component, OnInit, NgModule, Compiler, ComponentRef, Injector, NgModuleRef, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-template-viewer',
  templateUrl: './template-viewer.component.html',
  styleUrls: ['./template-viewer.component.scss']
})
export class TemplateViewerComponent implements OnInit {

  @Input() HTMLTemplate: string;
  constructor(private compiler: Compiler,
    private injector: Injector,
    private moduleRef: NgModuleRef<any>) { }

  ngOnInit(): void {
    this.createComponentFromRaw(this.HTMLTemplate, ()=>{})
  }

  @ViewChild('parent', { static: true, read: ViewContainerRef }) template: ViewContainerRef;

  private cmpRef: ComponentRef<any>;
  private createComponentFromRaw(template: string, onLoad?: any) {
    const templateComponent = Component({ template })(class {
      custom = {
        aptDate: new Date()
      }
      ngAfterViewInit() {
        onLoad(true);
      }
    });

    const tmpModule = NgModule({
      imports: [CommonModule],
      providers: [],
      declarations: [templateComponent],
    })(class { });

    this.compiler.compileModuleAndAllComponentsAsync(tmpModule)
      .then(factories => {
        const f = factories.componentFactories[0];
        this.cmpRef = f.create(this.injector, [], null, this.moduleRef);
        this.cmpRef.instance.name = 'doc-viewer';
        this.template.insert(this.cmpRef.hostView);
      });
  }

}
