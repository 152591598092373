import { Action } from '@ngrx/store';
import { Practitioner } from './practitioner.model';

export const QUERY = '[Practitioner] query';
export const CANCEL_QUERY = '[Practitioner] cancel query';
export const ADDED = '[Practitioner] added';
export const SELECT = '[Practitioner] select';
export const ERROR = '[Practitioner] error';
export class Query implements Action {
  readonly type = QUERY;
}

export class CancelQuery implements Action {
  readonly type = CANCEL_QUERY;
  constructor(public payload?: any) {}
}

export class Added implements Action {
  readonly type = ADDED;
  constructor(public payload: Practitioner) {}
}

export class Select implements Action {
  readonly type = SELECT;
  constructor(public practitioner: Practitioner | string | null) {}
}

export class PractitionerError implements Action {
  readonly type = ERROR;
  constructor(public error: any) {}
}

export type All = Query | CancelQuery | Added | Select | PractitionerError;
