import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LogoutGuard } from './logout.guard';
import { FullComponent } from './layouts/full/full.component';
import { ReadonlyComponent } from './readonly/readonly/readonly.component';
import { ReadonlyDrawingComponent } from './readonly/readonly-drawing/readonly-drawing.component';
import { WinningPageComponent } from './my-care-rewards/winning-page/winning-page.component';
import { DrawingComponent } from './readonly/drawing/drawing.component';
import { RoleGuard } from './role.guard';
import { DetailComponent } from './communication-v1/components/detail/detail.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'app/communication',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    canActivate: [LogoutGuard],
    component: FullComponent,
  },
  {
    path: 'app',
    component: FullComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: 'communication',
        loadChildren: () => import('./communication-v1/communication-v1.module').then((m) => m.CommunicationV1Module),
        canActivate: [AuthGuard],
      },
      {
        path: 'care-rewards',
        loadChildren: () => import('./my-care-rewards/my-care-rewards.module').then((m) => m.MyCareRewardsModule),
      },
      {
        path: 'offers',
        loadChildren: () => import('./my-offers/my-offers.module').then((m) => m.MyOffersModule),
      },
      {
        path: 'detail/:id',
        component: DetailComponent,
        canActivate: [AuthGuard],
      }
    ],
  },
  {
    path: 'ro',
    canActivate: [AuthGuard],
    component: ReadonlyComponent,
    children: [
      {
        path: 'drawing/:id',
        component: DrawingComponent,
      },
      {
        path: 'winners/:id',
        component: WinningPageComponent,
      },
    ],
  },
  {
    path: 'practitioner',
    canActivate: [AuthGuard],
    loadChildren: () => import('./practitioner/practitioner.module').then((m) => m.PractitionerModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
