import * as CommunicationActions from './communication.actions';
import * as CommunicationsModel from './communication.model';
import { ActionReducer } from '@ngrx/store';
import { communicationAdapter } from './communication.model';

type State = CommunicationsModel.State;
type Actions = CommunicationActions.All;

/// Reducer function
export const communicationReducer: ActionReducer<State, Actions> = (
  state = CommunicationsModel.initialState,
  action
) => {
  switch (action.type) {
    case CommunicationActions.QUERY:
      return {
        ...state,
        loading: true,
      }
    case CommunicationActions.ADDED:
      return communicationAdapter.addOne(action.payload, { ...state });
    case CommunicationActions.MODIFIED:
      return communicationAdapter.updateOne(
        {
          id: action.payload.id!,
          changes: action.payload,
        },
        { ...state, loading: false }
      );

    case CommunicationActions.REMOVED:
      return communicationAdapter.removeOne(action.payload.id!, { ...state, loading: false });

    case CommunicationActions.SEND_COMMUNICATION: {
      const message = action.payload;
      const attachments: CommunicationsModel.CommunicationPayload[] = message.attachments?.length && message.attachments.map(x=>{
        return {
          contentAttachment: {
            contentType: x.type,
            title: x.name
          },
          blob: x.rawFile
        }
      }) || [];
      const communication: CommunicationsModel.Communication = {
        id: message.tempId,
        text: {
          status: 'preparation',
          div: `<div>${message.msg}</div>`,
        },
        payload: [
          ...attachments,
          {
            contentString: message.msg,
            id: 'body',
          }
        ],
        sent: new Date().toISOString(),
        // include the "subject" based on the logged in patient ID
        ...(message.dynamicTemplate
          ? {
              dynamicTemplate: {
                reference: `DynamicTemplate/${message.dynamicTemplate}`,
              },
            }
          : {}),
        status: 'in-progress',
      };
      return communicationAdapter.addOne(communication, { ...state });
    }

    case CommunicationActions.SEND_COMMUNICATION_SUCCESS:
      return communicationAdapter.updateOne(
        {
          id: action.request.tempId,
          changes: action.payload,
        },
        { ...state, loading: false }
      );

    case CommunicationActions.SEND_COMMUNICATION_FAIL: {
      return communicationAdapter.updateOne(
        {
          id: action.request.tempId,
          changes: {
            status: 'failed',
          },
        },
        { ...state, loading: false, error: action.error }
      );
    }
    case CommunicationActions.SELECT:
      if (!action.communication) {
        return state;
      }
      return {
        ...state,
        selectedCommunication:
          typeof action.communication === 'string'
            ? state.entities[action.communication]
            : action.communication.resourceType === 'Communication'
            ? state.entities[action.communication.id!]
            : null,
      };
    case CommunicationActions.STOP_LOADER:
      return {
        ...state,
        loading: false,
      };
    case CommunicationActions.ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CommunicationActions.NEXT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageNumber: state.pagination.pageNumber + 1
        }
      };
    case CommunicationActions.ATTACHMENT_FILTER:
      return {
        ...state,
       filterCommunicationByAttachments: !state.filterCommunicationByAttachments
      };
    default:
      return state;
  }
};
