import { Action } from '@ngrx/store';
import { Template } from './template.model';

export const QUERY = '[Template] query';
export const CANCEL_QUERY = '[Template] cancel query';
export const ADDED = '[Template] added';
export const ERROR = '[Template] error';

export class Query implements Action {
  readonly type = QUERY;
  constructor(public payload?: any) {}
}

export class CancelQuery implements Action {
  readonly type = CANCEL_QUERY;
  constructor(public payload?: any) {}
}

export class Added implements Action {
  readonly type = ADDED;
  constructor(public payload: Template) {}
}

export class TemplateError implements Action {
  readonly type = ERROR;
  constructor(public error: any) {}
}

export type All = Query | CancelQuery | Added | TemplateError;
