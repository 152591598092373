import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { MenuItems } from '../../../util/menu-items';
// import { CommunicationService } from '../../services/communication/communication.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatSidenav } from '@angular/material/sidenav';
import { UserEffects } from 'src/app/state/user';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnDestroy, AfterViewInit {
  @ViewChild('snav') snav: MatSidenav;

  mobileQuery: MediaQueryList;
  sidebarOpened: boolean;
  logo = environment.tenant.logo;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public auth: UserEffects // private communicationService: CommunicationService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 960px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // this.communicationService.sidebarMenu.subscribe((res: boolean)=>{
    //   if(res)
    //     this.snav.toggle();
    // })
  }

  ngAfterViewInit(){
    if(this.mobileQuery){
      this.snav.close();
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logout(): void {
    // Call this to log the user out of the application
    this.auth.logout();
  }

  onClickMenuOption(){
    this.snav.close();
  }
}
