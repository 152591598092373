export const roleSettings = {
  sideMenu: {
    appointment: {
      hide: true,
      disable: false,
      lock: false,
    },
    communication: {
      hide: false,
      disable: false,
      lock: false,
    },
    offers: {
      hide: false,
      disable: false,
      lock: false,
    },
    carerewards: {
      hide: false,
      disable: false,
      lock: false,
    },
  },
};
