import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommunicationEffect, recipientId, AttachmentFilter, getAttachmentFilterState } from '../state/communications';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AppState } from '../state';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import * as $ from "jquery";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ChatService } from './chat.service';
import * as mime from 'mime/lite';
import { noWhitespaceValidator } from 'src/util/helper';

@Component({
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
})

export class CommunicationComponent implements OnInit, OnDestroy {
  recipient_id?: string;
  recipientSubscriber: Subscription;
  messageForm: FormGroup;
  attachmentFilter: Observable<boolean>;
  multipleFileSelection:boolean = true;
  hasBaseDropZoneOver = false;
  uploader: FileUploader = new FileUploader({
    allowedFileType: [
      "xlsx",
      "doc",
      "docx",
      "pdf",
      "gif",
      "jpg",
      "jpeg",
      "png",
      "txt",
      "image",
      "xls",
      "mp3",
      "m4a",
      "flac",
      "wav",
      "wma",
      "aac",
      "audio"
    ],
  });
  constructor(
    private chatService: ChatService,
    private sanitizer: DomSanitizer,
    private FB: FormBuilder,
    private store: Store<AppState>,
    private effects: CommunicationEffect) {
      this.recipientSubscriber = this.store.select(recipientId).subscribe(id=>{
        this.recipient_id = id;
      })
      this.attachmentFilter = this.store.select(getAttachmentFilterState);
    }

  ngOnInit(): void {
    this.generatePatientForm();
  }

  generatePatientForm(){
    this.messageForm = this.FB.group({
      message: ['', [Validators.required, noWhitespaceValidator]]
    })
  }

  sendCommunication(formDirective: FormGroupDirective) {
    if(this.messageForm.valid){
    if (!this.recipient_id) {
      throw new Error('Unable to determine recipient');
    }
    const msg = this.messageForm.value.message;
    formDirective.resetForm();
    this.effects.sendCommunication({
      recipient_id: this.recipient_id,
      recipient_role: 'Practitioner',
      msg,
      attachments: this.getFiles(),
      dynamicTemplate: ''
    }); 
    this.uploader.clearQueue();
  }
}

  ngOnDestroy(): void {
    this.recipientSubscriber.unsubscribe();
  }
 
  onClickUploadFile(e: MouseEvent) {
    e.preventDefault();
    $("#selectedFile").click();
  }


  fileOverBase(event: boolean): void {
    this.hasBaseDropZoneOver = event;
  }


  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map(fileItem => {
      return fileItem.file;
    });
  }

  onFileSelected(event: any){
    if(this.uploader.queue.length>1 && !this.multipleFileSelection){
      this.uploader.queue[0].remove();
    }
    console.log(this.uploader.queue)
  }

  onFileDropped(){
    if(!this.multipleFileSelection && this.uploader.queue.length>1){
      for(let i=0; i<this.uploader.queue.length; i++){
        this.uploader.queue[i].remove();
      }
    }
  }

  getFileType(file: FileLikeObject | undefined){
    if(file){
      const ext = mime.getExtension(file.type);
      if(ext){
       return this.chatService.fileType.find(x=> x.ext.includes(ext));
      }
    }
   return null;
  }
  
  safeURL(file: any): SafeUrl{
    return this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)));
  }

  filter(){ 
    this.store.dispatch(new AttachmentFilter());
  }

}
