import { initialState } from './reward.model';
import { QUERY, CANCEL_QUERY, All, STOP_LOADER, ERROR } from './reward.actions';

export function rewardssReducer(state = initialState, action: All) {
  switch (action.type) {
    case QUERY:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_QUERY:
      return {
        ...state,
        entities: {},
        ids: [],
        loading: false,
      };
    case STOP_LOADER:
      return {
        ...state,
        loading: false,
      };
    case ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
  }
  return state;
}
