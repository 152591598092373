import { Appointment } from '../app/state/appointment';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { basename } from './path';
import { FormControl } from '@angular/forms';

export const getScheduleIdForAppointment = (appointment: Appointment): string => {
  return appointment
    .supportingInformation!.filter((i: any) => i.scheduleId || i.reference?.startsWith('Schedule/'))
    .map((i: any) => i.scheduleId || i.reference!.slice(9))
    .find((s: any) => s);
};

export const extractId = (data: fhir.Reference) => {
  return data.reference ? basename(data.reference) : undefined;
};

export const dateFormat = (date: string | number | Date, format: DateTimeFormatOptions = DateTime.DATE_SHORT) => {
  let dateTime: DateTime;
  switch (typeof date) {
    case 'string':
      dateTime = DateTime.fromISO(date);
      break;
    case 'number':
      dateTime = DateTime.fromMillis(date);
      break;
    default:
      dateTime = DateTime.fromJSDate(date);
  }
  return dateTime.toLocaleString(format);
};

export const noWhitespaceValidator = (control: FormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}