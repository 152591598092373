import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DrawingAwardRule, Reward } from '@carecognitics/fhir-resources';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { RewardService } from 'src/app/services/reward.service';
import { UserEffects } from 'src/app/state/user';
import { environment } from 'src/environments/environment';
import { MoreTicketsComponent } from './components/more-tickets/more-tickets.component';

interface ITime {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}
const source = interval(1000);

@Component({
  selector: 'app-drawing',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.component.scss'],
})
export class DrawingComponent implements OnDestroy {
  drawingAwardRulePrize: DrawingAwardRule[] = [];
  drawingId: string | null;
  drawingDetails: any = {};
  timeleft: ITime = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  };

  drawingSkeleton = true;
  ticketSkeleton = true;
  totalTickets = 0;
  userId: string | null;
  rewards: Reward[] = [];
  authUserSubscriber: Subscription;

  constructor(
    private dialog: MatDialog,
    private rewardService: RewardService,
    private toastr: ToastrService,
    public auth: UserEffects,
    private activeRoute: ActivatedRoute
  ) {
    this.drawingId = this.activeRoute.snapshot.paramMap.get('id');
    if (this.drawingId) {
      this.getDrawing(this.drawingId);
    }
    const id = this.activeRoute.snapshot.queryParamMap.get('pid');
    this.authUserSubscriber = this.auth.user$.subscribe((user: any) => {
      if (user && user.uid && id === user.uid.slice(8)) {
        this.userId = user.uid.slice(8);
      } else if (user && user[`${environment.auth0PractitionerConfig.audience}practitioner_id`]) {
        this.userId = user[`${environment.auth0PractitionerConfig.audience}practitioner_id`];
      }
    });
  }

  searchRaffleTickets(drawingId: string, userId: string) {
    this.ticketSkeleton = true;
    this.rewardService.getTicketsForDrawing(drawingId, userId).subscribe(
      (response: Reward[]) => {
        this.ticketSkeleton = false;
        let totalTickets = 0;
        let rewards: Reward[] = [];
        response.map((r) => {
          totalTickets += r.rewardAmount;
          const tickets = [];
          for (let i = 0; i < r.rewardAmount; i++) {
            tickets.push({
              ...r,
              ticketNo: `${r.ticketNo}-${i + 1}`,
            });
          }
          rewards = [...rewards, ...tickets];
        });
        this.rewards = rewards;
        this.totalTickets = totalTickets;
      },
      (error: any) => {
        this.ticketSkeleton = false;
        this.toastr.warning(error, 'Tickets', {
          progressBar: true,
          timeOut: 3000,
        });
      }
    );
  }

  getDrawing(drawingId: string) {
    this.rewardService.getDrawingById(drawingId).subscribe(
      (response: any) => {
        this.drawingDetails = response;
        this.drawingSkeleton = false;
        if (this.userId) {
          this.searchRaffleTickets(this.drawingDetails.id, this.userId);
        } else {
          this.ticketSkeleton = false;
        }

        if (this.drawingDetails.awardRule) {
          this.drawingAwardRulePrize = this.drawingDetails.awardRule.filter(
            (item: DrawingAwardRule) => item.ruleType === 'Prize' && item.status === 'Active'
          );
        }
        if (this.drawingDetails.runStatus === 'Not Run') this.setTimer(this.drawingDetails.runDate);
      },
      (error: any) => {
        this.drawingSkeleton = false;
        this.ticketSkeleton = false;
        this.toastr.warning(error, 'Drawing', {
          progressBar: true,
          timeOut: 3000,
        });
      }
    );
  }

  /**
   * This function is used to show the timer on UI
   * @param date
   */
  setTimer(date: string) {
    const countDownDate = new Date(date).getTime();

    const timer = source.subscribe(() => {
      const now = new Date().getTime();

      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      const days = this.prependZero(Math.floor(distance / (1000 * 60 * 60 * 24)));
      const hours = this.prependZero(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      const minutes = this.prependZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      const seconds = this.prependZero(Math.floor((distance % (1000 * 60)) / 1000));

      this.timeleft = { days, hours, minutes, seconds };

      // If the count down is over, write some text
      if (distance < 0) {
        timer.unsubscribe();
      }
    });
  }

  prependZero(time: any) {
    if (time <= 0 || isNaN(time)) {
      return '00';
    } else if (time > 0 && time <= 9) {
      return '0' + time;
    }
    return time;
  }

  showMoreTickets() {
    this.dialog.open(MoreTicketsComponent, {
      width: '710px',
      height: '450px',
      data: {
        drawingDetails: this.drawingDetails,
        totalTickets: this.totalTickets,
        rewards: this.rewards,
      },
    });
  }

  ngOnDestroy() {
    if (this.authUserSubscriber) {
      this.authUserSubscriber.unsubscribe();
    }
  }

  logout(): void {
    this.auth.logout();
  }
}
