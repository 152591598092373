/// <reference types="@types/fhir" />
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';
import { DynamicTemplate } from '@carecognitics/fhir-resources';

export interface Template extends DynamicTemplate {}

export const templateAdapter = createEntityAdapter<DynamicTemplate>({
  sortComparer: (c1, c2) => c2.meta!.lastUpdated!.localeCompare(c1.meta!.lastUpdated!),
});

export interface State extends EntityState<DynamicTemplate> {
  loading: boolean;
  error?: any;
}

export const initialState: State = templateAdapter.getInitialState({ loading: false });

export const getTemplateState = createFeatureSelector<State>('template');

export const { selectIds, selectAll, selectEntities, selectTotal } = templateAdapter.getSelectors(
  getTemplateState
);