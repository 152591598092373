import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { Observable, Subscription } from 'rxjs';
import { ChatService } from 'src/app/communication/chat.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { Communication, AttachmentFilter, CommunicationEffect, CommunicationPayload, getAttachmentFilterState, isLoadingCommunications, recipientId, selectAllCommunicationsWithTemplate, StopLoader } from 'src/app/state/communications';
import { noWhitespaceValidator } from 'src/util/helper';
import * as mime from 'mime/lite';
import * as $ from "jquery";
import { IUser, UserEffects } from 'src/app/state/user';
import { CommunicationHandlerService } from 'src/app/services/communication-handler.service';
import { debounceTime, map } from 'rxjs/operators';
import { CommunicationService } from 'src/app/state/communications/communication.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { PopupContainerComponent } from '../popup-container/popup-container.component';
import { MatDialog } from '@angular/material/dialog';

interface Icons extends Record<string, any> {
  EMAIL: {
    icon: string;
    secureIcon: string;
  };
  SMS: {
    icon: string;
    secureIcon: string;
  };
  PORTAL: {
    icon: string;
    secureIcon: string;
  };
}

@Component({
  selector: 'app-chat-container',
  templateUrl: './chat-container.component.html',
  styleUrls: ['./chat-container.component.scss']
})
export class ChatContainerComponent implements OnInit {
  communications: Communication[] | undefined;
  communicationSubscriber: Subscription;
  loaderSubscriber: Subscription;
  user: Observable<IUser>;
  moment = moment;
  loader: boolean;
  recipient_id?: string;
  recipientSubscriber: Subscription;
  messageForm: FormGroup;
  attachmentFilter: Observable<boolean>;
  multipleFileSelection: boolean = true;
  hasBaseDropZoneOver = false;
  uploader: FileUploader = new FileUploader({
    allowedFileType: [
      "xlsx",
      "doc",
      "docx",
      "pdf",
      "gif",
      "jpg",
      "jpeg",
      "png",
      "txt",
      "image",
      "xls",
      "mp3",
      "m4a",
      "flac",
      "wav",
      "wma",
      "aac",
      "audio"
    ],
  });

  icons: Icons = {
    EMAIL: {
      icon: 'assets/images/patient-engagement/unsecure-email.png', // 'mail_outline',
      secureIcon: 'assets/images/patient-engagement/secure-email.png',
    },
    SMS: {
      icon: 'assets/images/patient-engagement/insecure-sms.png',
      secureIcon: 'assets/images/patient-engagement/secure-sms.png',
    },
    PORTAL: {
      icon: 'assets/images/patient-engagement/secure-sms.png',
      secureIcon: 'assets/images/patient-engagement/secure-sms.png',
    },
  };
  @ViewChild('chatBubbleContainer') private chatBubbleContainer: ElementRef;
  constructor(
    public chatService: ChatService,
    private sanitizer: DomSanitizer,
    private FB: FormBuilder,
    private store: Store<AppState>,
    private userEffects: UserEffects,
    private communicationService: CommunicationService,
    private communicationHandlerService: CommunicationHandlerService,
    private effects: CommunicationEffect,
    private dialog: MatDialog) {

    this.moment.updateLocale('en', {
      calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'dddd',
        nextWeek: 'dddd',
        sameElse: 'L'
      }
    });
    this.recipientSubscriber = this.store.select(recipientId).subscribe(id => {
      this.recipient_id = id;
    })
    this.attachmentFilter = this.store.select(getAttachmentFilterState);
    this.user = this.userEffects.user$;
    this.communicationHandlerService.getCommunications();
  }

  detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  ngOnInit(): void {
    this.generatePatientForm();

    this.loaderSubscriber = this.store
      .select(isLoadingCommunications)
      .subscribe((loader: boolean) => (this.loader = loader));
    this.communicationSubscriber = this.store
      .select(selectAllCommunicationsWithTemplate)
      .pipe(
        debounceTime(250),
        map((communications: Communication[]): Communication[] => {
          if (communications.length) {
            //Change status to read
            communications = communications.filter(c=> c.primaryMedium !=='LOG_CALL')
            const ids: string[] = communications
              .filter(
                (i: Communication) =>
                  i.status !== 'completed' && i.sender && i.sender.reference?.startsWith('Practitioner/')
              )
              .map((i: Communication) => <string>i.id);
            if (ids && ids.length) {
              this.communicationService.updateStatus(ids).subscribe();
            }
            communications = _.orderBy(communications, 'sent')
            return communications.map((communication: Communication) => {
              return { ...communication, extractedData: this.formatCommunication(communication) };
            });
          } else return communications;
        })
      )
      .subscribe((communications: Communication[] | undefined) => {
        this.communications = communications;
        if (
          (this.communicationHandlerService.communicationQueryCalled || (communications && communications.length)) &&
          this.loader
        ) {
          this.store.dispatch(new StopLoader());
          this.communicationHandlerService.communicationFetched();
        }
        setTimeout(() => {
          this.scrollToBottom();
        }, 200)
      });
  }

  formatCommunication(communication: Communication) {
    const isSecure =
      (communication.category &&
        communication.category.length &&
        communication.category[0].coding?.length &&
        communication.category[0].coding[0].code === 'SecureMessage') ||
      false;
    const medium =
      communication.medium?.length && communication.medium[0].coding?.length && communication.medium[0].coding[0].code;
    const key = Object.keys(this.icons).find((x) => medium && medium.replace('_', '') === x) || 'PORTAL';
    const icons = this.icons[key];

    const attachments: CommunicationPayload[] | undefined = communication
      .payload && communication
        .payload.filter((y) => y.contentAttachment)
        .map((x) => {
          const ext = mime.getExtension(x.contentAttachment!.contentType!);
          const fileDetail = this.chatService.fileType.find((y) => y.ext.includes(ext!));
          const retValue: CommunicationPayload = { ...x };
          if ((fileDetail && fileDetail.type === 'image') || (fileDetail && fileDetail.type === 'audio')) {
            retValue.loading = true;
            this.chatService.getFileBlob(communication.id!, x.id!).subscribe((blob: Blob) => {
              retValue.loading = false;
              retValue.blob = blob;
            });
          }
          retValue.fileDetail = fileDetail;
          return retValue;
        });

    return {
      secure: isSecure,
      messageMedium: medium || 'PORTAL',
      icon: isSecure ? icons.secureIcon : icons.icon,
      type: communication.sender && communication.sender.reference!.startsWith('Practitioner') ? 'left' : 'right',
      attachments,
      msg: communication.payload && communication.payload.filter(x => x.contentString).map(x => x.contentString)[0] || null, // to handle empty string bodies for existing messages without payloads or empty contentStrings
      name:
        communication?.practitioner &&
        communication?.practitioner.name &&
        communication?.practitioner.name.length &&
        communication?.practitioner?.name[0]?.text,
      isReadMore: true,
    };
  }

  generatePatientForm() {
    this.messageForm = this.FB.group({
      message: ['', [Validators.required, noWhitespaceValidator]]
    })
  }

  sendCommunication(formDirective: FormGroupDirective) {
    if (this.messageForm.valid) {
      if (!this.recipient_id) {
        throw new Error('Unable to determine recipient');
      }
      const msg = this.messageForm.value.message;
      formDirective.resetForm();
      this.effects.sendCommunication({
        recipient_id: this.recipient_id,
        recipient_role: 'Practitioner',
        msg,
        attachments: this.getFiles(),
        dynamicTemplate: ''
      });
      this.uploader.clearQueue();
    }
  }

  ngOnDestroy(): void {
    this.recipientSubscriber.unsubscribe();
  }

  onClickUploadFile(e: MouseEvent) {
    e.preventDefault();
    $("#selectedFile").click();
  }

  trackByMethod(index: number, el: any): number {
    return el.id;
  }

  fileOverBase(event: boolean): void {
    this.hasBaseDropZoneOver = event;
  }


  getFiles(): FileLikeObject[] {
    return this.uploader.queue.map(fileItem => {
      return fileItem.file;
    });
  }

  onFileSelected(event: any) {
    if (this.uploader.queue.length > 1 && !this.multipleFileSelection) {
      this.uploader.queue[0].remove();
    }
    console.log(this.uploader.queue)
  }

  onFileDropped() {
    if (!this.multipleFileSelection && this.uploader.queue.length > 1) {
      for (let i = 0; i < this.uploader.queue.length; i++) {
        this.uploader.queue[i].remove();
      }
    }
  }

  getFileType(file: FileLikeObject | undefined) {
    if (file) {
      const ext = mime.getExtension(file.type);      
      if (ext) {
        return this.chatService.fileType.find(x => x.ext.includes(ext));
      }
    }
    return null;
  }

  safeURL(file: any): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file)));
  }

  filter() {
    this.store.dispatch(new AttachmentFilter());
  }

  scrollToBottom(): void {
    try {
      this.chatBubbleContainer.nativeElement.scrollTop = this.chatBubbleContainer.nativeElement.scrollHeight;
    } catch (err) { console.log(err) }
  }

  onPreviewMessage(data: any) {
    this.dialog.open(PopupContainerComponent, {
      panelClass: 'custom-dialog-container',
      minWidth: '50vw',
      maxWidth: '70vw',
      maxHeight: '90vh',
      data: {
        type: 'messageTemplate',
        htmlText: data
      },
    });
  }
}
