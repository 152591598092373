import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take, map, skip } from 'rxjs/operators';
import { UserEffects } from './state/user';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private user: UserEffects) {}

  canActivate(): Observable<boolean> {
    return this.user.isLoading$.pipe(
      switchMap((loading) => {
        if (!loading) {
          return this.user.userId$.pipe(
            take(1),
            map((u) => {
              return u.includes('Patient/');
            })
          );
        } else {
          return this.user.userId$.pipe(
            skip(1),
            map((u) => {
              return u.includes('Patient/');
            })
          );
        }
      })
    );
  }
}
