import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  key: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  key: string;
  name: string;
  type?: string;
  child?: SubChildren[];
  imageUrl?: string;
  transparentImageUrl?: string;
}

export interface Menu {
  state: string;
  key: string;
  name: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
  imageUrl?: string;
  transparentImageUrl?: string;
}

const MENUITEMS: Array<Menu> = [
  {
    state: 'communication',
    key: 'communication',
    name: 'My Messages',
    type: 'link',
    icon: 'message',
  },
  {
    state: 'appointment',
    key: 'appointment',
    name: 'Appointment',
    type: 'link',
    icon: 'card_giftcard',
  },
  {
    state: 'offers',
    key: 'offers',
    name: 'My Offers',
    type: 'link',
    icon: 'redeem',
  },
  {
    state: 'care-rewards',
    key: 'carerewards',
    name: 'My Care Rewards',
    type: 'link',
    imageUrl: 'assets/images/logo/MyCareReward_Logo.png',
    transparentImageUrl: 'assets/images/logo/MyCareReward_Logo_White.png',
  },
];
/**
 * This is class contains the configuration for menu items, If you need to add a new menu item in sidebar then you need to add that item here
 */
@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
