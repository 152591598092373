import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Drawing, Reward } from '@carecognitics/fhir-resources';

export interface DialogData {
  drawingDetails: Drawing;
  totalTickets: number;
  rewards: Reward[];
}

@Component({
  selector: 'app-more-tickets',
  templateUrl: './more-tickets.component.html',
  styleUrls: ['./more-tickets.component.scss']
})
export class MoreTicketsComponent {

  constructor(
    public dialogRef: MatDialogRef<MoreTicketsComponent>,
    @Inject(MAT_DIALOG_DATA) public popup: DialogData) {}

  onClose(): void {
    this.dialogRef.close();
  }

}
