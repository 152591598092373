import type { Schedule } from '@carecognitics/fhir-resources';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../state';

export const schedulesFeatureKey = 'schedules';

export interface State extends EntityState<Schedule> {
  loading: boolean;
  error?: any;
  selectedSchedule?: Schedule | null;
}

export const schedulesAdapter = createEntityAdapter<Schedule>({
  sortComparer: (a, b) => b?.planningHorizon?.start?.localeCompare(a.planningHorizon!.start!) || 0, // sort descending by start of planningHorizon
});

export const initialState: State = schedulesAdapter.getInitialState({ loading: false });

export const getSchedulesState = createFeatureSelector<AppState, State>(schedulesFeatureKey);
export const getSelectedSchedule = createSelector(getSchedulesState, (state) => state.selectedSchedule);
export const isLoadingSchedules = createSelector(getSchedulesState, (state) => state.loading);

export const { selectIds, selectAll: selectAllSchedules, selectEntities, selectTotal } = schedulesAdapter.getSelectors(
  getSchedulesState
);

export { Schedule };
