import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as slotActions from './slot.actions';
import { Slot } from './slot.model';
import { AppState } from '../state';
import firebase from 'firebase/app';
import 'firebase/firestore';

type Action = slotActions.All;

export const SlotQuery = {
  isLoading: (state: AppState) => state.slots.loading,
};

@Injectable()
export class SlotEffects {
  private cancelQueryState$ = new Subject<void>();
  private cancelQueryObservable$ = this.cancelQueryState$.asObservable();
  private queryState$ = new Subject<boolean>();
  private queryObservable$ = this.queryState$.asObservable();
  isLoading$ = this.store.select(SlotQuery.isLoading);
  query$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<slotActions.Query>(slotActions.QUERY),
      tap(() => this.cancelQueryState$.next()), // cancel any queries in progress
      switchMap((query) => {
        return this.db
          .collection<Slot>('Slot', (f: firebase.firestore.Query) => {
            if (query.scheduleId) {
              f = f.where('schedule.reference', '==', `Schedule/${query.scheduleId}`);
            }
            return f.orderBy('start', 'desc');
          })
          .stateChanges()
          .pipe(
            tap(() => this.queryState$.next(true)),
            takeUntil(this.cancelQueryObservable$.pipe(tap(() => this.queryState$.next(false))))
          );
      }),
      mergeMap((d) => d),
      map((action) => {
        return {
          type: `[Slot] ${action.type}`,
          slot: {
            ...action.payload.doc.data(),
          },
        } as Action;
      })
    )
  );
  cancelQuery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(slotActions.CANCEL_QUERY),
        // tap(() => this.queryComponent$.next()), // do we need to trigger one value?
        switchMap(() => this.queryObservable$.pipe(debounceTime(environment.firestore.unsubscribeDelay))),
        take(1),
        tap(() => this.cancelQueryState$.next())
      ),
    {
      dispatch: false,
    }
  );

  public cancelQuery() {
    this.store.dispatch(new slotActions.CancelQuery());
  }

  public query(scheduleId?: string) {
    this.store.dispatch(new slotActions.Query(scheduleId));
  }

  public select(slot: Slot | null) {
    this.store.dispatch(new slotActions.Select(slot));
  }

  constructor(private actions$: Actions, private db: AngularFirestore, private store: Store<AppState>) {}
}
