import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchHttpError } from '../../util/http';
@Injectable({
  providedIn: 'root',
})
export class PractitionerService {
  constructor(private http: HttpClient) {}

  getFirebaseToken() {
    return this.http.get(`${environment.services.practitioner}/firebase/token`).pipe(catchHttpError);
  }
}
