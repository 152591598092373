<div
  class="button-container"
  [ngClass.gt-xs]="{ 'p-b-0': true, 'p-l-0': true, 'm-l-5': true }"
  fxLayout="row"
  fxHide.gt-sm
  fxLayoutAlign="center start"
>
  <div fxFlex.lt-sm="100" fxFlex="70">
    <button mat-raised-button color="primary" (click)="allCommunications()">
      <mat-icon>chevron_left</mat-icon> All communications
    </button>
  </div>
</div>
<div *ngIf="!loader && (communication | async); let message" fxLayoutAlign="center start">
  <mat-card class="container" fxFlex.lt-sm="100" fxFlex="70" [ngClass.lt-sm]="{ 'full-height': true }">
    <mat-card-content class="section1">
      <div class="m-b-5 from" *ngIf="message.fromEmail">
        <span><b>From: </b></span><span>{{ message.fromEmail }}</span>
      </div>
      <div class="m-b-5 to" *ngIf="message.toEmail">
        <span><b>To: </b></span><span>{{ message.toEmail }}</span>
      </div>
      <div class="m-b-5">
        <span><b>Date: </b></span><span>{{ message.sent | date: 'MM/dd/yyyy' }}</span>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div class="section2" *ngIf="!message.template">
        <h4 class="msg m-t-20 m-b-20" *ngIf="message.extractedData && message.extractedData.msg">
          {{ message.extractedData.msg.contentString }}
        </h4>
      </div>
      <div class="p-20" *ngIf="message.template">
        <app-template-viewer
          [HTMLTemplate]="message.extractedData.msg.contentString"
          *ngIf="message.extractedData && message.extractedData.msg && message.extractedData.msg.contentString"
        >
        </app-template-viewer>
      </div>
      <div fxLayout="row" *ngIf="message.extractedData.attachments && message.extractedData.attachments.length">
        <div
          class="attachment-container pointer"
          *ngFor="let attachment of message.extractedData.attachments"
          (click)="chatService.downloadFile(message.id!, attachment)"
        >
          <span *ngIf="attachment.fileDetail!.type === 'image'" fxLayout="center">
            <img
              width="30"
              *ngIf="!attachment.loading && attachment.blob"
              [src]="chatService.safeURL(attachment.blob)"
            />
            <ngx-skeleton-loader
              *ngIf="attachment.loading"
              count="1"
              [theme]="{ height: '40px', width: '30px', marginBottom: '0', marginRight: '10px' }"
            ></ngx-skeleton-loader>
          </span>
          <img width="30" *ngIf="attachment.fileDetail!.type !== 'image'" [src]="attachment.fileDetail!.src" />
          <span class="m-l-5">{{ attachment.contentAttachment!.title }}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div fxLayoutAlign="center start" *ngIf="loader">
  <mat-card class="container" fxFlex.lt-sm="100" fxFlex="70" [ngClass.lt-sm]="{ 'full-height': true }">
    <mat-card-content class="section1">
      <div>
        <ngx-skeleton-loader count="1" [theme]="{ height: '23px', width: '50%', 'margin-bottom': '0px' }">
        </ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader count="1" [theme]="{ height: '23px', width: '50%', 'margin-bottom': '0px' }">
        </ngx-skeleton-loader>
      </div>
      <div>
        <ngx-skeleton-loader count="1" [theme]="{ height: '23px', width: '50%', 'margin-bottom': '0px' }">
        </ngx-skeleton-loader>
      </div>
    </mat-card-content>
    <mat-card-content>
      <div fxFlex>
        <ngx-skeleton-loader count="1" [theme]="{ height: '100px', 'margin-bottom': '-5px' }"></ngx-skeleton-loader>
      </div>
    </mat-card-content>
  </mat-card>
</div>
