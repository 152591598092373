<div class="container">
    <div class="chat-bubble-card" #chatBubbleContainer>
        <!-- <div fxLayout="row" class="chat-bubble-title">
            This is a confirmation that your message has been assigned to our staff to help you with your scheduling
            request. If this is an emergency please call 911 instead.
        </div> -->
        <div *ngFor="let communication of communications; let i=index; trackBy: trackByMethod">
            <div class="m-b-10" fxLayout="row" fxLayoutAlign="start center"
                *ngIf="i===0 || (communication.sent | date: 'dd MMMM yyyy') !== (communications && communications[i-1] && communications[i-1].sent | date: 'dd MMMM yyyy')">
                <div class="line"></div>
                <span class="day-name">{{moment(communication.sent).calendar()}}</span>
                <div class="line"></div>
            </div>
            <div *ngIf="communication.extractedData.type==='left'" fxLayout="row" class="left">
                <div class="profile-circle">
                    <img src="assets/icons/communications/profile-circle.svg">
                </div>
                <div>
                    <div class="sender-name">{{communication.extractedData.name}}</div>
                    <div fxLayout="row">
                        <div class="bubble-card">
                            <div>
                                <div class="msg"
                                    *ngIf="communication.extractedData.attachments.length===0 && communication.extractedData.msg && !communication.dynamicTemplate"
                                    [innerHTML]="communication?.extractedData?.msg | safeHtml"></div>
                                <div fxLayout="row wrap" class="msg"
                                    *ngIf="communication.extractedData.attachments && communication.extractedData.attachments.length">
                                    <span class="m-r-5" *ngIf="communication.extractedData.msg"
                                        [innerHTML]="communication.extractedData.msg | safeHtml"></span>
                                    <div fxLayout="row wrap" fxLayoutAlign="start center"
                                        class="attachment-container m-r-10 pointer"
                                        *ngFor="let attachment of communication.extractedData.attachments"
                                        (click)="chatService.downloadFile(communication.extractedData.id, attachment)">
                                        <span *ngIf="attachment.fileDetail && attachment.fileDetail.type === 'image'">
                                            <img width="30" *ngIf="!attachment.loading && attachment.blob"
                                                [src]="safeURL(attachment.blob)">
                                            <ngx-skeleton-loader *ngIf="attachment.loading" count="1"
                                                [theme]="{ height: '40px', width: '30px', marginBottom: '0', marginRight: '10px'}">
                                            </ngx-skeleton-loader>
                                        </span>
                                        <img width="30"
                                            *ngIf="attachment.fileDetail && attachment.fileDetail.type !== 'image' && attachment.fileDetail.type !== 'audio'"
                                            [src]="attachment.fileDetail!.src">
                                        <audio controls
                                            *ngIf="attachment.fileDetail && attachment.fileDetail.type === 'audio' && attachment.blob"
                                            [src]="safeURL(attachment.blob)">
                                        </audio>
                                        <span class="m-l-5">{{attachment.contentAttachment!.title}}</span>
                                    </div>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="end center" class="msg template"
                                    (click)="onPreviewMessage(communication?.extractedData?.msg)"
                                    *ngIf="communication.template">
                                    <span>{{communication.template?.name}}</span>
                                </div>
                            </div>
                            <div>
                                <img src="assets/icons/communications/secure.svg">
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start">
                            <span class="time">{{communication.sent | date:'HH:mm a'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="communication.extractedData.type==='right'" fxLayout="row" class="right"
                fxLayoutAlign="end center">
                <div fxLayout="row">
                    <div fxLayout="row" fxLayoutAlign="start start">
                        <span class="time">{{communication.sent | date:'HH:mm a'}}</span>
                        <img *ngIf="communication.status === 'preparation' || communication.status === 'in-progress'"
                            width="25" src="assets/icons/communications/single-tick.svg">
                        <img *ngIf="communication.status === 'completed'" width="25"
                            src="assets/icons/communications/double-ticks.svg">
                    </div>
                    <div class="bubble-card">
                        <div>
                            <div class="msg"
                                *ngIf="communication.extractedData.attachments.length===0 && communication.extractedData.msg && !communication.dynamicTemplate"
                                [innerHTML]="communication?.extractedData?.msg | safeHtml"></div>
                            <div fxLayout="row wrap" class="msg"
                                *ngIf="communication.extractedData.attachments && communication.extractedData.attachments.length">
                                <span class="m-r-5" *ngIf="communication.extractedData.msg"
                                    [innerHTML]="communication.extractedData.msg | safeHtml"></span>
                                <div fxLayout="row wrap" fxLayoutAlign="start center"
                                    class="attachment-container m-r-10 pointer"
                                    *ngFor="let attachment of communication.extractedData.attachments"
                                    (click)="chatService.downloadFile(communication.extractedData.id, attachment)">
                                    <span *ngIf="attachment.fileDetail && attachment.fileDetail.type === 'image'">
                                        <img width="30" *ngIf="!attachment.loading && attachment.blob"
                                            [src]="safeURL(attachment.blob)">
                                        <ngx-skeleton-loader *ngIf="attachment.loading" count="1"
                                            [theme]="{ height: '40px', width: '30px', marginBottom: '0', marginRight: '10px'}">
                                        </ngx-skeleton-loader>
                                    </span>
                                    <img width="30"
                                        *ngIf="attachment.fileDetail && attachment.fileDetail.type !== 'image' && attachment.fileDetail.type !== 'audio'"
                                        [src]="attachment.fileDetail!.src">
                                    <audio controls
                                        *ngIf="attachment.fileDetail && attachment.fileDetail.type === 'audio' && attachment.blob"
                                        [src]="safeURL(attachment.blob)">
                                    </audio>
                                    <span class="m-l-5">{{attachment.contentAttachment!.title}}</span>
                                </div>
                            </div>
                            <div fxLayout="row wrap" fxLayoutAlign="end center" class="msg template"
                                (click)="onPreviewMessage(communication.extractedData.msg)"
                                *ngIf="communication.dynamicTemplate">
                                <span>{{communication.extractedData?.dynamicTemplate?.name}}</span>
                            </div>
                        </div>
                        <div fxLayoutAlign="end start">
                            <img src="assets/icons/communications/secure.svg">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form (ngSubmit)="sendCommunication(formDirective)" #formDirective="ngForm" [formGroup]="messageForm">
        <div class="footer">
            <div id="attachments" fxLayout="row" *ngIf="uploader.queue.length"
                style="overflow-x: auto;padding-top: 10px; padding-bottom: 5px;">
                <div *ngFor="let item of uploader.queue">
                    <div class="attachement-item m-b-5" *ngIf="getFileType(item?.file)" fxLayoutAlign="start center">
                        <img width="30" *ngIf="getFileType(item?.file)!.type === 'image'" [src]="safeURL(item._file)">
                        <img width="30"
                            *ngIf="getFileType(item?.file)!.type !== 'image' && getFileType(item?.file)!.type !== 'audio'"
                            [src]="getFileType(item?.file)!.src">
                        <audio controls *ngIf="getFileType(item?.file)!.type === 'audio'" [src]="safeURL(item._file)">
                        </audio>
                        <span class="file-name" style="font-size: 12px; color: #fff;">{{ item?.file?.name }}</span>
                        <div class="pointer close-circle-btn" (click)="item.remove();"
                            style="position: absolute; right: -9px; top: -9px;">
                            <mat-icon color="warn"
                                style="background: #e6e4e4; border-radius: 50%; font-size: 18px; width: 18px;height: 18px;">
                                close</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" ng2FileDrop (fileOver)="fileOverBase($event)"
                (onFileDrop)="onFileDropped()" [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" [uploader]="uploader">
                <input type="file" accept=".xlsx,.xls,image/*,.doc, .docx,.txt,.pdf,audio/*" ng2FileSelect
                    [uploader]="uploader" id="selectedFile" style="display: none;" [multiple]="multipleFileSelection"
                    (change)="onFileSelected($event)" />
                <span fxLayoutAlign="center center" class="attachment-icon" (click)="onClickUploadFile($event)"
                    [matBadge]="uploader.queue.length>0? uploader.queue.length : null" matBadgeColor="warn"
                    matBadgeSize="medium">
                    <img width="25" src="assets/icons/communications/attachment.svg">
                </span>
                <div fxLayout="row" style="position: relative; width: 100%;">
                    <img class="secure-icon" src="assets/icons/communications/secure.svg">
                    <input formControlName="message" class="inputbox" type="text"
                        placeholder="Type your secure message...">
                    <button type="submit" class="send-btn" fxLayoutAlign="center center">
                        <img src="assets/icons/communications/send-icon.svg">
                    </button>
                </div>
            </div>
            <div style="margin-top: 10px;padding-bottom: 10px;" fxLayout="row" fxLayoutAlign="center center">
                <img width="190" src="assets/icons/communications/logo.svg">
            </div>
        </div>
    </form>
</div>